var ImportEmployees = {
    init: function() {
        
    },

    setupImportEmployees: function() {
        $('#import-upload').fileupload({
            dataType: 'json',
            done: function (e, data) {
                var form = $('[name=file]').closest('form');
                ImportEmployees.displayErrors(form);

                if (data.result.file)
                {
                    $('[name=file]').val(data.result.file);
                    $('.config-import').html(data.result.html);

                    // ImportEmployees.initSwitchers($('.config-import'));
                    // ImportEmployees.existingDepartments = $('.imported-departments-dropdown:first option').map(function() {
                    //     if (this.value != "") return this.value;
                    // }).get();

                    $('.panel-form-container .btn-submit').prop('disabled',false);
                }
                else if(data.result.errors)
                {
                    $('.config-import').html('');
                    $('.panel-form-container .btn-submit').prop('disabled',true);

                    ImportEmployees.displayErrors(form, data.result.errors);
                }

                $('#progress').delay(1000).fadeOut('slow',function() {
                    $(this).find('.progress-bar').css('width','0%');
                });
            },
            progressall: function (e, data) {
                var progress = parseInt(data.loaded / data.total * 100, 10);
                $('#progress .progress-bar').css('width',progress + '%');
            },
            change: function (e, data) {
                $('#progress').fadeIn();
                $('#progress .progress-bar').css('width',0 + '%');
            }
        }).prop('disabled', !$.support.fileInput).parent().addClass($.support.fileInput ? undefined : 'disabled');

        $('.panel-form-container .btn-submit').on('click', function(e) {
            e.preventDefault();

            var btn = $(this),
                form = $(btn).closest('form'),
                container = $(form).closest('.panel-form-container').parent();

            ImportEmployees.hideErrors(form);

            $(btn).ladda().ladda('start');

            $.ajax({
                url: $(form).attr('action'),
                data: $(form).serialize(),
                method: 'post'
            }).done(function (r) {
                if (r.success) {
                    if (r.html && r.html != '') $(container).html(r.html);
                    if (r.msg && r.msg != '') $(container).find('form').prepend('<div class="alert alert-success" style="display:none;">'+r.msg+'</div>');
                    if (r.url && r.url != '' && history.replaceState) window.history.replaceState( {} , null, r.url);
                    $(container).find('.alert').slideDown('fast');
                    ImportEmployees.reloadList();

                    if (r.callback) {
                        var fn = r.callback.split('.'),
                            run = window[fn[0]][fn[1]];

                        if (typeof run === "function") run(r);
                    }
                }
                else {
                    ImportEmployees.displayErrors(form, r.errors);
                    $(btn).ladda().ladda('stop');
                }
            });
        });
    },

    importProgressQueue: function(r) {
        var container = $('.panel-form-container'),
            form = $(container).find('form'),
            btn = $(form).find('.btn-submit');

        if (r.queue_status && r.queue_status.last_page > r.queue_status.current_page)
        {
            // update batch progress
            var progress = (r.queue_status.current_page / r.queue_status.last_page) * 100;
            $('#import-progress').fadeIn();
            $('#import-progress .progress-bar').css('width', progress + '%');

            cpv = r.queue_status.current_page + 1;

            $.ajax({
                url: $(form).attr('action') + '?page=' + cpv,
                data: $(form).serialize(),
                method: 'post'
            }).done(function (r) {
                if (r.success) {
                    if (r.msg && r.msg != '') $(container).find('form').prepend('<div class="alert alert-success" style="display:none;">'+r.msg+'</div>');
                    $(container).find('.alert').slideDown('fast');
                    
                    // TODO: reload employees panel
                    ImportEmployees.reloadList();

                    if (r.callback) {
                        var fn = r.callback.split('.'),
                            run = window[fn[0]][fn[1]];

                        if (typeof run === "function") run(r);
                    }
                }
                else {
                    ImportEmployees.displayErrors(form, r.errors);
                    $(btn).ladda().ladda('stop');
                }
            });
        }
        else
        {
            $(container).find('form').prepend('<div class="alert alert-success" style="display:none;">Import has been completed.</div>');
            $('.config-import').html('');
            $('#import-progress .progress').html('');
            $(container).find('.alert').slideDown('fast');
            $(btn).ladda().ladda('stop');
            $(btn).prop('disabled',true);
        }
    },

    reloadList: function() {
        console.log('reload list');
    },

    displayErrors: function(form, errors) {
        var errors = errors ? errors : {};

        if ($(form).length)
        {
            $(form).find('.has-error').removeClass('has-error has-feedback');
            $(form).find('.form-control-feedback').remove();
            $(form).find('.alert').slideUp('fast', function() { $(this).remove(); });


            if (!$.isEmptyObject(errors)) {
                var main_error_msg = '';

                $(form).prepend('<div class="alert alert-danger" style="display:none">There have been errors during saving the form.</div>');

                $.each(errors, function (k, v) {
                    if (typeof v === "object" && v.constructor.toString().indexOf('Array') == -1) {
                        $.each(v, function(kk,vv) {
                            var error_msg = '<span class="glyphicon glyphicon-remove form-control-feedback" data-toggle="popover" data-trigger="hover" data-placement="bottom" data-html="true" data-content="\<ul class=\'errors list-unstyled\'\>';

                            $.each(vv, function(i,msg) {
                                var html_msg = '\<li\>'+msg+'\</li\>';
                                if ($(form).find('[name='+k+'\\['+kk+'\\]'+']').length) {
                                    error_msg += html_msg;
                                }
                                else main_error_msg += html_msg;
                            });
                            if ($(form).find('[name='+k+'\\['+kk+'\\]'+']').length) $(form).find('[name='+k+'\\['+kk+'\\]'+']').closest('.form-group').addClass('has-error has-feedback').append(error_msg+'\</ul\>"></span>');
                        });
                    } else {
                        var error_msg = '<span class="glyphicon glyphicon-remove form-control-feedback" data-toggle="popover" data-trigger="hover" data-placement="bottom" data-html="true" data-content="\<ul class=\'errors list-unstyled\'\>';
                        $.each(v, function(i,msg) {
                            var html_msg = '\<li\>'+msg+'\</li\>';
                            if ($(form).find('[name='+k+']').length) {
                                error_msg += html_msg;
                            }
                            else main_error_msg += html_msg;
                        });
                        if ($(form).find('[name='+k+']').length) $(form).find('[name='+k+']').closest('.form-group').addClass('has-error has-feedback').append(error_msg+'\</ul\>"></span>');
                    }
                });

                if (main_error_msg != '') $(form).find('.alert').append('<ul class="errors">'+ main_error_msg +'</ul>');
                $(form).find('.alert').slideDown('fast');

                Public.initPopovers(form);
            }
        }
    },

    hideErrors: function(form) {
        ImportEmployees.displayErrors(form, {});
    },

};

// $(function() {
//     ImportEmployees.init();
// });
