var CaseInstance = {
    ajaxUrl: (window.location.pathname),

    init: function() {
        var uri = new URI();

        $('#case-details-container').on('click','#case-status-options li:not(.active) a', function(e){
            CaseInstance.caseStatusUpdate(e.target);
        });

        $('.show-assign-employee').on('click', function() {
            if ($('.search-employee-to-assign-to-case').hasClass('hidden')) {
                $('.search-employee-to-assign-to-case').removeClass('hidden');
                $('.no-employee-msg').addClass('hidden');
            } else {
                $('.search-employee-to-assign-to-case').addClass('hidden');
                $('.no-employee-msg').removeClass('hidden');

            }
        });
    },

    caseStatusUpdate: function(lnk) {
        var $link = $(lnk),
            spinner = Utils.spinner;

        $('.ajaxloader').fadeIn().append(spinner.el);

        $.ajax({
            url: CaseInstance.ajaxUrl,
            type: 'POST',
            data: {
                'action'            : 'update_status',
                'update_status_id'  : $link.data('case-status-id')
            },
            success: function(data) {
                $('#case-details-container').html(data);
                CaseActivities.reloadActivities();
            },
            error: function() {
            }
        });
    },
};


$(function() {
    CaseInstance.init();
});
