var ReallocateContactForm = {
    selected_contact: null,
    selected_contact_customer_count: 0,

    init: function() {
        var uri = new URI(),
            wrapper = $('.reallocate-form-wrapper');

        ReallocateContactForm.calculateBarChart();

        $(wrapper).on('click','.select-contact-to-reallocate', ReallocateContactForm.selectContactToReallocate)
                  .on('click','.reallocate', ReallocateContactForm.reallocate);

    },

    selectContactToReallocate: function(e) {
        e.preventDefault();

        var btn = $(this),
            url = (window.location.pathname),
            spinner = Utils.spinner,
            wrapper = $('.reallocate-form-wrapper'),
            contact_wrapper = $(btn).closest('.contact-wrapper'),
            contact_id = $(contact_wrapper).data('contactId'),
            contact_name = $(contact_wrapper).find('.description .name').html()
            customers_count = $(contact_wrapper).find('.chart-bar').data('customersCount'),
            customers_wrapper = $(contact_wrapper).find('.contact-customers-list-wrapper'),
            deselect = false;


        if ($(contact_wrapper).hasClass('selected')) {
            $(wrapper).find('.instruction').html('Select contact to reallocate');
            ReallocateContactForm.selected_contact = null;
            ReallocateContactForm.selected_contact_customer_count = null;
            deselect = true;

        } else {
            ReallocateContactForm.selected_contact = contact_id;
            ReallocateContactForm.selected_contact_customer_count = customers_count;
        }

        ReallocateContactForm.resetCountsAndChart();

        if (deselect) return;

        $(contact_wrapper).addClass('selected');
        $(wrapper).find('.instruction').html(contact_name+' selected for reallocation of '+customers_count+' clients.');
        $(customers_wrapper).html(spinner.el);

        $.ajax({
            url: url,
            type: 'post',
            data: {
                task: 'load-customers-list',
                contact_id: contact_id
            }
        }).done(function(r) {
            if (r.success && r.contact_customers_html) {
                $(customers_wrapper).html(r.contact_customers_html);

                ReallocateContactForm.setupCustomersListForm();
            }
            else {
                $(customers_wrapper).html('Clients could not be loaded at this time.');
            }
        });
    },

    reallocate: function(e) {
        e.preventDefault();

        var btn = $(this),
            url = (window.location.pathname),
            spinner = Utils.spinner,
            wrapper = $('.reallocate-form-wrapper'),
            contact_wrapper = $(btn).closest('.contact-wrapper'),
            customers_wrapper = $(contact_wrapper).find('.contact-customers-list-wrapper');
            form = $(btn).closest('form');

        $(customers_wrapper).append('<div class="ajaxloader"></div>').find('.ajaxloader').fadeIn().html(spinner.el);

        $.ajax({
            url: url,
            type: 'post',
            data: $(form).serialize()
        }).done(function(r) {
            if (r.success) {

                if (r.contact_id && r.new_contact_id) {
                    var customers_number = r.customers || 0,
                        cases_number = r.cases || 0,
                        new_contact_wrapper = $('.contact-wrapper[data-contact-id='+r.new_contact_id+']'),

                        selected_contact_customers_count = $(contact_wrapper).find('.chart-bar').data('customersCount'),
                        selected_contact_cases_count = $(contact_wrapper).find('.chart-bar').data('casesCount'),
                        new_contact_customers_count = $(new_contact_wrapper).find('.chart-bar').data('customersCount'),
                        new_contact_cases_count = $(new_contact_wrapper).find('.chart-bar').data('casesCount');

                    selected_contact_customers_count = parseInt(selected_contact_customers_count) - customers_number;
                    selected_contact_cases_count = parseInt(selected_contact_cases_count) - cases_number;
                    new_contact_customers_count = parseInt(new_contact_customers_count) + customers_number;
                    new_contact_cases_count = parseInt(new_contact_cases_count) + cases_number;

                    $(contact_wrapper).find('.customers-count-label').html(selected_contact_customers_count);
                    $(contact_wrapper).find('.cases-count-label').html(selected_contact_cases_count);
                    $(new_contact_wrapper).find('.customers-count-label').html(new_contact_customers_count);
                    $(new_contact_wrapper).find('.cases-count-label').html(new_contact_cases_count);

                    $(contact_wrapper).find('.chart-bar').data('customersCount',selected_contact_customers_count);
                    $(contact_wrapper).find('.chart-bar').data('casesCount',selected_contact_cases_count);
                    $(new_contact_wrapper).find('.chart-bar').data('customersCount',new_contact_customers_count);
                    $(new_contact_wrapper).find('.chart-bar').data('casesCount',new_contact_cases_count);

                    ReallocateContactForm.calculateBarChart();
                }

                if (r.contact_customers_html) {
                    $(customers_wrapper).html(r.contact_customers_html);
                    ReallocateContactForm.setupCustomersListForm();
                }
            }
        });
    },


    resetCountsAndChart: function() {
        var wrapper = $('.reallocate-form-wrapper');

        // reset customers list checkboxes
        $(wrapper).find('.contact-wrapper.selected .contact-customers-list-wrapper').html(''),

        $(wrapper).find('.contact-wrapper.selected').removeClass('selected');
    },

    calculateBarChart: function() {
        var wrapper = $('.reallocate-form-wrapper'),
            total_width = $('.contact-details').width() * 0.1,
            top_customer_count = 0,
            width_ratio = 1;

        $(wrapper).find('.contact-details .chart-bar').each(function() {
            var customers_count = $(this).data('customersCount');
            if (customers_count > top_customer_count) top_customer_count = customers_count;
        });

        $(wrapper).find('.contact-details .chart-bar').each(function() {
            var customers_count = $(this).data('customersCount'),
                cases_count = $(this).data('casesCount');

            var width_ratio = Math.round((top_customer_count / (top_customer_count + total_width)) * 100) / 100, //total_width / 100 * top_customer_count,
                customers_bar_width = width_ratio * customers_count,
                cases_bar_width = width_ratio * cases_count;

            $(this).find('.customers-count').width(customers_bar_width+'px');
            $(this).find('.cases-count').css({
                left:  customers_bar_width+'px',
                width: cases_bar_width+'px'
            });


        });
    },

    setupCustomersListForm: function() {
        Utils.setupTables();
        Utils.setupTooltips();

        var contact_wrapper = $('.reallocate-form-wrapper .contact-wrapper.selected'),
            customers_list_table = $(contact_wrapper).find('.contact-customers-list-wrapper .contact-customers-list .table');

        $(customers_list_table).DataTable( {
            "scrollY":          "647px",
            "scrollCollapse":   true,
            "paging":           false,
            "searching":        false,
            "info":             false,
            "order": [
                [ 2, "desc" ]
            ],
            "columnDefs": [
                { "orderable": false, "targets": 0 },
                { "orderable": false, "targets": 4 }

            ],
            "destroy": true
        } );
    },
}

$(function() {
    if ($('.reallocate-form-wrapper').length) {
        ReallocateContactForm.init();
    }
});
