var PhoneNumbers = {
    row_curr_highest_index: 0,

    init: function() {
        if ($('#phone-numbers-panel-buttons').length > 0 && $('.phone-number-row').length > 0) {
            if ($('.phone-number-row div.form-group input:last').attr('name').match(/^phonenumbers\[(\d+)\].+$/)) {
                PhoneNumbers.row_curr_highest_index = $('.phone-number-row div.form-group input:last').attr('name').match(/^phonenumbers\[(\d+)\].+$/)[1];
            }
            else {
                PhoneNumbers.row_curr_highest_index = 0;
            }
        }

        $('.phone-numbers-panel').each(function() {
            PhoneNumbers.displayNoRowsMessage(this);
        });

        $('body').on('click','#phone-numbers-panel-buttons a.add', function(e) {
            var $lnk = $(this);
            e.preventDefault();
            PhoneNumbers.addNewRow($lnk);
            $(this).tooltip('hide');
        });

        $('body').on('click', '.phone-numbers a.remove-phone-number', function(e) {
            var $lnk = $(this);
            e.preventDefault();

            PhoneNumbers.removeRow($lnk);
        });
    },

    addNewRow: function(lnk) {
        var wrapper = $(lnk).closest('.phone-numbers-panel'),
            $newRow = $(wrapper).find('.phone-number-row-template').clone();
        $newRow.find('select').val('');
        $newRow.find('input').val('');
        $newRow.removeClass('phone-number-row-template hidden').addClass('phone-number-row');
        $newRow.find('select, input').removeAttr('disabled');

        if ($(wrapper).find('.phone-number-row').length == 0) {
            $(wrapper).find('#no-rows-message').after($newRow);
            $(wrapper).find('#no-rows-message').remove();
        }
        else {
            $(wrapper).find('.phone-number-row').last().after($newRow);
        }

        PhoneNumbers.row_curr_highest_index++;
        $(wrapper).find('.phone-number-row:last :input').each(function() {
            var $obj = $(this),
                newName = $obj.attr('name').replace(/^(phonenumbers\[)\d+(\].+)$/, '$1' + PhoneNumbers.row_curr_highest_index + '$2');

            $obj.attr('name', newName);
            $obj.attr('id', newName);
        });
    },

    removeRow: function($lnk) {
        var wrapper = $(lnk).closest('.phone-numbers-panel'),
            curRow = $lnk.parents('.phone-number-row');
        curRow.remove();

        PhoneNumbers.displayNoRowsMessage(wrapper);

        // nb don't reduce row_count so that array doesn't need re-indexing
    },

    displayNoRowsMessage: function(wrapper) {
        if ($(wrapper).find('.phone-number-row').length == 0) {
            $(wrapper).find('#phone-numbers-panel-buttons').before(
                '<div id="no-rows-message">To add a phone number use the link below</div>'
            );
        }
    }
};


$(function() {
    PhoneNumbers.init();
});
