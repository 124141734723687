var FieldTypeahead = {

    init: function() {
        $('body').on('change', '.typeahead-lookup', FieldTypeahead.openAddNewModal);
        $('body').on('click', 'span.btn.employee-search', FieldTypeahead.searchEmployee);
        $('body').on('click', 'span.btn.employee-assign-to-case', FieldTypeahead.assignEmployeeToCase);

        $('.typeahead-county').each(function() {
            var $field_display = $(this),
                counties = [];

            $.get('/admin/dashboard/load-counties-data', function(data) {
                $field_display.typeahead({
                    source: data,
                    minLength: 0,
                    autoSelect: true
                });
            });
        });

        $('.typeahead-lookup').each(function() {
            FieldTypeahead.initIndividualTypeahead($(this));
        });
    },

    initIndividualTypeahead: function(fld_display) {
        var $field_display = $(fld_display),
            $field_hidden = $field_display.closest('.form-group').find('input[type=hidden]:not([name*="[id]"])'),
            lookup_url = '/admin/dashboard/lookup-data?model='+$field_display.data('model'),
            field_value = $field_hidden.val(),
            $employee_search_btn = $('span.btn.employee-search'),
            $employee_assign_btn = $('span.btn.employee-assign-to-case'),
            is_employee_field = $field_display.hasClass('employee'),
            is_assign_to_case_field = $field_display.hasClass('assign-to-case');

        if ($field_display.attr('data-customer-id')) {
            lookup_url += '&customer_id=' + $field_display.attr('data-customer-id');
        }

        if ($field_display.attr('data-contact-role')) {
            lookup_url += '&contact_role=' + $field_display.attr('data-contact-role');
        }

        if ($field_display.attr('data-is-internal') && $field_display.data('is-internal')) {
            lookup_url += '&is_internal=1';
        }

        if ($field_display.attr('data-only-employees') && $field_display.data('only-employees')) {
            lookup_url += '&only_employees=1';
        }

        if ($field_display.attr('data-only-authorised-contacts') && $field_display.data('only-authorised-contacts')) {
            lookup_url += '&only_authorised_contacts=1';
        }

        if (field_value) {
            $field_display.siblings('.typeahead-loading-icon').addClass('loading');
            $field_display.prop('disabled', true);
            $.get(lookup_url+'&id='+field_value, function(data) {
                if (data.length > 0 && data[0].name) {
                    $field_display.val(data[0].name);
                }
                $field_display.siblings('.typeahead-loading-icon').removeClass('loading');
                $field_display.prop('disabled', false);
            })
            .done(function() {
                if ($field_hidden.prop('disabled') || $field_hidden.hasClass('disabled')) {
                    $field_display.prop('disabled', true);
                }
            });
        }

        var inModal = $field_display.parents('.modal').length > 0 ? true : false;

        $field_display.typeahead({
            source: function(query, process) {
                return $.get(lookup_url+'&s='+query, function(data) {
                    return process(data);
                }, 'json');
            },
            afterSelect: function(i) {
                this.$element.trigger('bootstrap3typeahead:select', [i]);
            },
            delay: 250,
            minLength: 0,
            items: 11,
            autoSelect: true,
            addItem: inModal ? false : (
                $field_display.data('createNewOption') ? {
                    id: 'new' + $field_display.data('model'),
                    name: 'Create new'
                    // TODO: assign to case here
                } : false )
        });

        $field_display.change(function() {
            var current = $field_display.typeahead("getActive");

            if (is_employee_field) {
                $employee_search_btn.attr('disabled', true);
                $employee_assign_btn.attr('disabled', true);
            }

            if (current) {
                if (current.name == $field_display.val()) {
                    $field_hidden.val(current.id).trigger('change');

                    if (is_employee_field) {

                        if (is_assign_to_case_field) {
                            $employee_assign_btn.attr('disabled', false);
                            $employee_assign_btn.click();

                        } else {
                            $employee_search_btn.attr('disabled', false);
                            $employee_search_btn.click();
                        }

                    }

                } else {
                    $field_hidden.val('').trigger('change');
                }
            }
            else {
                $field_hidden.val('');
            }
        });
    },

    openAddNewModal: function() {
        var $field_hidden = $(this).closest('.form-group').find('input[type=hidden]:not([name*="[id]"])'),
            ajaxUrl = null;

        switch ($field_hidden.val()) {
            case 'newContact' :
                ajaxUrl = '/admin/contacts/add';
                break;
            case 'newCustomer' :
                ajaxUrl = '/admin/customers/add';
                break;
            default :
                return;
        }

        $.ajax({
            url: ajaxUrl,
            type: 'GET',
            data: {
                'action'    : 'inModal'
            },
            success: function(data) {
                $('#lookup-modal .modal-body .contents').html(data);
                $('#lookup-modal').modal('show');
                //Utils.setupSubmitButtons();
                FieldTypeahead.init();

                switch ($field_hidden.val()) {
                    case 'newContact' :
                        ContactForm.init();

                        EmailAddresses.displayNoRowsMessage($('#lookup-modal').find('.email-addresses-panel'));

                        break;
                    case 'newCustomer' :
                        CustomersForm.init();
                        break;
                    default :
                        return;
                }

                PhoneNumbers.displayNoRowsMessage($('#lookup-modal').find('.phone-numbers-panel'));
                // PhoneNumbers.init();


            },
            error: function() {
            }
        });
    },

    updateTypeaheadFromModal: function(modalType, val) {
        var $hidden_field = $('input[value="'+modalType+'"]'),
            $typeahead_field = $hidden_field.siblings('.typeahead-lookup');

        $hidden_field.val(val);
        FieldTypeahead.init();
    },

    searchEmployee: function() {
        var $employee_id_field = $('input[name="employee_id"]');

        if ($employee_id_field.val() != '') {
            if ($employee_id_field.val() == 'newContact') {
                var uri = new URI('/admin/contacts/add?');

                if ($employee_id_field.attr('data-customer-id') && $employee_id_field.data('customer-id')) {
                    uri.setSearch('customer_id', $employee_id_field.data('customer-id'));
                }

                if ($employee_id_field.attr('data-contact-type-id') && $employee_id_field.data('contact-type-id')) {
                    uri.setSearch('contact_type_id', $employee_id_field.data('contact-type-id'));
                }

                window.location.href = uri.toString();
            }
            else {
                window.location.href = "/admin/contacts/show/" + $employee_id_field.val();
            }
        }
    },

    assignEmployeeToCase: function() {
        var $employee_id_field = $('input[name="employee_id"]');

        if ($employee_id_field.val() != '') {
            if ($employee_id_field.val() == 'newContact') {
                var uri = new URI('/admin/contacts/add?');

                if ($employee_id_field.attr('data-customer-id') && $employee_id_field.data('customer-id')) {
                    uri.setSearch('customer_id', $employee_id_field.data('customer-id'));
                }

                if ($employee_id_field.attr('data-contact-type-id') && $employee_id_field.data('contact-type-id')) {
                    uri.setSearch('contact_type_id', $employee_id_field.data('contact-type-id'));
                }

                if ($employee_id_field.attr('data-case-instance-id') && $employee_id_field.data('case-instance-id')) {
                    uri.setSearch('case_instance_id', $employee_id_field.data('case-instance-id'));
                }

                window.location.href = uri.toString();
            }
            else {
                //window.location.href = "/admin/contacts/show/" + $employee_id_field.val();
                // TODO: ajax call to assign the employee to case_instance
                var case_instance_id = $employee_id_field.data('caseInstanceId'),
                    employee_id = $employee_id_field.val();

                if (case_instance_id && employee_id)
                {
                    $.ajax({
                        url: "/admin/case_instances/show/" + case_instance_id,
                        type: 'post',
                        data: {
                            action: "assign_employee",
                            contact_id: employee_id
                        }
                    }).done(function(r) {
                        if (r.success && r.html) {
                            var new_html = $(r.html).find('.employee-panel-wrapper');
                            $employee_id_field.closest('.panel-body').html(new_html);
                            $('.show-assign-employee').remove();
                        }
                    });
                }
            }
        }
    }
}

$(function() {
    FieldTypeahead.init();
});
