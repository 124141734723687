var ContactForm = {
    selected_contact_type: null,
    selected_internal_contact_type: null,
    selected_employee_contact_type: null,
    selected_authorised_contact_type: null,
    selected_do_not_contact_type: null,
    selected_address_not_required: null,
    selected_department: null,
    selected_team: null,
    selected_role: null,
    customer_id: null,
    contact_type_id: null,
    case_instance_id: null,

    init: function() {
        var uri = new URI();
        ContactForm.customer_id = $('input[name="customer_id"]').val().length ? $('input[name="customer_id"]').val() : ( uri.hasQuery('customer_id') ? uri.query(true).customer_id : null );
        ContactForm.contact_type_id = $(':input[name="contact_type_id"]').val().length && $(':input[name="contact_type_id"]').val() != '' ? $(':input[name="contact_type_id"]').val() : ( uri.hasQuery('contact_type_id') ? uri.query(true).contact_type_id : null );
        ContactForm.case_instance_id = uri.hasQuery('case_instance_id') ? uri.query(true).case_instance_id : null;

        if (ContactForm.contact_type_id) {
            $(':input[name="contact_type_id"]').val(ContactForm.contact_type_id);
        }

        ContactForm.selected_contact_type = $('select[name=contact_type_id]').val();
        ContactForm.selected_department = $('select[name=contact_type_id]').data('department') || $('select[name=department_id]').val();
        ContactForm.selected_team = $('select[name=contact_type_id]').data('team') || $('select[name=team_id]').val();
        ContactForm.selected_role = $('select[name=contact_type_id]').data('role') || $('select[name=role_id]').val();

        $('input[name="customer_id"]').val(ContactForm.customer_id);

        ContactForm.checkSelectedContactType();
        $('body').on('change', 'select[name=contact_type_id]', ContactForm.checkSelectedContactType);
        $('body').on('change', 'select[name=department_id]', ContactForm.reloadTeamsSelect);

        $('.modal .contact-form-wrapper .custom-form .submit-btn').on('click', function(e) {
            $(this).closest('form').submit();
        });
        $('.modal .contact-form-wrapper .custom-form').on('submit', ContactForm.inModalSubmit);

        $('#lookup-modal').on('click', '.cancel-btn', function(e) {console.log('here');
            e.preventDefault();
            $('#lookup-modal').modal('hide');
        });

        ContactForm.setupDatepicker();
    },

    inModalSubmit: function(e) {
        e.preventDefault();

        var formData = $('.modal .contact-form-wrapper .custom-form').serializeArray();

        $.ajax({
            url: '/admin/contacts/add',
            type: 'POST',
            data: formData,
            success: function(data) {
                if (data.hasOwnProperty('success')) {
                    $('#lookup-modal').modal('hide');
                    FieldTypeahead.updateTypeaheadFromModal('newContact', data.contact_id);
                }
                else {
                    $('#lookup-modal .modal-body .contents').html(data);
                    FieldTypeahead.init();
                }
            },
            error: function() {
            }
        });

    },

    checkSelectedContactType: function() {
        var $contact_type = $('select[name=contact_type_id]');

        ContactForm.selected_contact_type = $contact_type.val();
        ContactForm.selected_internal_contact_type = $contact_type.find('option:selected').data('internal');
        ContactForm.selected_employee_contact_type = $contact_type.find('option:selected').data('employee');
        ContactForm.selected_authorised_contact_type = $contact_type.find('option:selected').data('authorised-contact');
        ContactForm.selected_do_not_contact_type = $contact_type.find('option:selected').data('do-not-contact');
        ContactForm.selected_address_not_required = $contact_type.find('option:selected').data('address-not-required');

        ContactForm.toggleFieldsForInternalContact();
        ContactForm.toggleSectionsForEmployeeContact();
    },

    toggleFieldsForInternalContact: function() {
        var $department_select = $('select[name=department_id]'),
            $role_select = $('select[name=role_id]'),
            $department = $department_select.closest('.form-group'),
            $team = $('select[name=team_id]').closest('.form-group'),
            $role = $role_select.closest('.form-group'),
            $managed_by = $('[name=managed_by]').closest('.form-group'),
            $password_fields = $('.password-fields'),
            $inactive = $('[name=inactive]:checkbox').closest('.form-group'),
            $can_manage_complaints = $('[name=can_manage_complaints]:checkbox').closest('.form-group');

        if (ContactForm.selected_internal_contact_type) {
            $department.removeClass('hidden');
            $('.addresses-panel, .phone-numbers-panel').find('.form-group.required').attr('data-required','true').removeClass('required');

            $department_select.prop('disabled',false);
            if (ContactForm.selected_department) {
                $department_select.find('option[value='+ContactForm.selected_department+']').prop('selected',true);
            }

            if ($department_select.val()) {
                $team.removeClass('hidden');
                ContactForm.reloadTeamsSelect();
            }

            // $managed_by.removeClass('hidden');

            $role.removeClass('hidden');
            $role_select.prop('disabled',false);

            $password_fields.removeClass('hidden');
            $password_fields.find('.form-control').prop('disabled',false);

            $inactive.removeClass('hidden');
            $inactive.find('[name=inactive]').prop('disabled',false);

            $can_manage_complaints.removeClass('hidden');
            $can_manage_complaints.find('[name=can_manage_complaints]').prop('disabled',false);

            if (ContactForm.selected_role) {
                $role_select.find('option[value='+ContactForm.selected_role+']').prop('selected',true);
            }
        } else {
            $department.addClass('hidden');
            $team.addClass('hidden');
            $role.addClass('hidden');
            // $managed_by.addClass('hidden');
            $('.addresses-panel, .phone-numbers-panel').find('.form-group[data-required=true]').addClass('required').attr('data-required','false');

            $department.find('select').prop('disabled',true);
            $team.find('select').prop('disabled',true);
            $role.find('select').prop('disabled',true);

            $password_fields.addClass('hidden');
            $password_fields.find('.form-control').prop('disabled',true);

            $inactive.addClass('hidden');
            $inactive.find('[name=inactive]').prop('disabled',true);

            $can_manage_complaints.addClass('hidden');
            $can_manage_complaints.find('[name=can_manage_complaints]').prop('disabled',true);
        }
    },

    reloadTeamsSelect: function() {
        var selected_department = $('select[name=department_id]').val();
            $teams_select = $('select[name=team_id]'),
            $teams = $teams_select.closest('.form-group'),
            $contact_type_select = $('select[name=contact_type_id]');
            url = $contact_type_select.data('reloadUrl'),
            task = $contact_type_select.data('reloadTask');

        $teams.removeClass('hidden');

        $.ajax({
            url: url,
            data: {
                action: task,
                department_id: selected_department,
                team_id: ContactForm.selected_team
            }
        }).done(function(r) {
            $teams_select.parent().html(r);
        });
    },

    toggleSectionsForEmployeeContact: function() {
        var $emp_panels = $('div.panel[data-employee-panel], [data-employee-field]'),
            $authcont_panels = $('[data-authorised-contact-field]'),
            $managed_by = $('[name=managed_by]').closest('.form-group');

        // if (ContactForm.selected_employee_contact_type || ContactForm.selected_authorised_contact_type) {
        // if (ContactForm.selected_authorised_contact_type) {
        //     // Show save and create case button
        //     $('.employee-submit-btn').removeClass('hidden');
        // }

        if (ContactForm.selected_employee_contact_type || ContactForm.selected_authorised_contact_type || ContactForm.selected_do_not_contact_type) {


            if (ContactForm.selected_employee_contact_type) {
                // Show the panels relevant for both employee and auth contact types
                $emp_panels.each(function() {
                    $(this).removeClass('hidden');
                    $(this).find(':input').prop('disabled', false);
                });
            }

            $managed_by.addClass('hidden');

            // Show that customer_id required for both employee and auth contact types
            $('input[name="customer_id"]').closest('.form-group').addClass('required');

            // Only for employee contact types, hide email/ phone numbers and addresses
            if (ContactForm.selected_employee_contact_type && (!ContactForm.selected_authorised_contact_type || !ContactForm.selected_do_not_contact_type))
            {
                // $('.email-addresses-panel, .addresses-panel, .phone-numbers-panel').each(function() {
                $('.email-addresses-panel, .phone-numbers-panel').each(function() {
                    $(this).addClass('hidden');
                    $(this).find('.phone-number-row :input:enabled:not(.primary-email-address)').prop('disabled', true);
                });

                // Show save and create case button
                $('.employee-submit-btn').removeClass('hidden');
            }

            // Only for authorised contact types, show phone numbers and addresses not required
            if (ContactForm.selected_authorised_contact_type || ContactForm.selected_do_not_contact_type)
            {
                // $('.email-addresses-panel, .addresses-panel, .phone-numbers-panel').each(function() {
                $('.email-addresses-panel, .phone-numbers-panel').each(function() {
                    $(this).removeClass('hidden');
                    $(this).find('.phone-number-row :input:not(.primary-email-address)').prop('disabled', false);
                });
                $('.addresses-panel, .phone-numbers-panel').find('.form-group.required').attr('data-required','true').removeClass('required');

                $authcont_panels.each(function() {
                    $(this).removeClass('hidden');
                    $(this).find(':input').prop('disabled', false);
                });

                // Hide save and create case button
                $('.employee-submit-btn').addClass('hidden');
            }
        }
        else {
            // hide panels, disable fields
            $emp_panels.each(function() {
                $(this).addClass('hidden');
                $(this).find(':input').prop('disabled', true);
            });

            $authcont_panels.each(function() {
                $(this).addClass('hidden');
                $(this).find(':input').prop('disabled', true);
            });

            $managed_by.removeClass('hidden');

            // Show email/ phone numbers and addresses
            // $('.email-addresses-panel, .addresses-panel, .phone-numbers-panel').each(function() {
            $('.email-addresses-panel, .phone-numbers-panel').each(function() {
                $(this).removeClass('hidden');
                $(this).find('.phone-number-row :input:disabled:not(.primary-email-address)').prop('disabled', false);
            });

            console.log(269, 'enable .email-addresses-panel, .phone-numbers-panel');

            // Setup customer_id not requird, phone numbers and address required
            // Do nothing if internal contact type
            if (!ContactForm.selected_internal_contact_type) {
                $('input[name="customer_id"]').closest('.form-group').removeClass('required');
                $('.addresses-panel, .phone-numbers-panel').find('.form-group[data-required=true]').addClass('required').attr('data-required','false');
            }

            // Hide save and create case button
            // if (!ContactForm.selected_authorised_contact_type) {
                $('.employee-submit-btn').addClass('hidden');
            // }
        }

        if (ContactForm.selected_address_not_required) {

            $('.addresses-panel').each(function() {
                $(this).addClass('hidden');
                $(this).find(':input:enabled').prop('disabled', true);
            });
        
        } else {

             $('.addresses-panel').each(function() {
                $(this).removeClass('hidden');
                $(this).find(':input:disabled').prop('disabled', false);
            });

        }
    },

    setupDatepicker: function() {
        if ($('.contact-form-wrapper .input-group.date').length && $.isFunction($.fn.datepicker)) {
            $('.contact-form-wrapper .datepicker').datepicker('destroy');
            $('.contact-form-wrapper .input-group.date').datepicker({
                format: "dd/mm/yyyy",
                todayBtn: "linked",
                container: $('.custom-form'),
                autoclose: true
            });
        }
    },

};

$(function() {
    if ($('.contact-form-wrapper').length) {
        ContactForm.init();
    }
});
