var SimpleListEdit = {

	form: null,
	list: null,
	add: null,
	remove: null,

	init: function() {
		form = $('.form-items'),
		list = $(form).find('.items-list'),
		add = $(form).find('.add-item');

		$('[data-toggle=tooltip]').tooltip();

		$(add).on('click', function(e) {
			e.preventDefault();
			SimpleListEdit.add(this);
		});

		$(list).on('click', '.remove-item', function(e) {
			e.preventDefault();
			SimpleListEdit.remove(this);
		});

		SimpleListEdit.sortables();
		SimpleListEdit.followupControls();
	},

	add: function(el) {
		var prepend = $(el).data('prepend') ? $(el).data('prepend') : false,
			current_form = $(el).closest('.form-items');
		if ($(current_form).find('.item-form-group').length > 200) {
			alert('There are too meny items, try to remove some not used ones and put new in their place.');
			return false;
		}
		var clone = $(current_form).find('.item-form-base').clone();
		clone.removeClass('item-form-base');

		if (prepend) $(list).prepend(clone);
		else $(list).append(clone);
		clone.find('.remove-item').tooltip();
		SimpleListEdit.sortables();
	},

	remove: function(el) {
		var group = $(el).closest('.item-form-group'),
			id = $(group).find('.item-id'),
			remove = $(group).find('.item-remove');

		if (id.val() == '') {
			$(group).fadeOut(300, function() {
				$(this).remove();
			});
		} else {
			if ($(remove).val() == '1') {
				$(group).find('input').attr('readonly',false);
				$(remove).val('')
				$(el).tooltip('destroy').removeClass('wud-undo2 text-muted').addClass('wud-remove2 text-danger').attr('title','Mark to be removed').tooltip();
			} else {
				$(group).find('input').attr('readonly',true);
				$(remove).val('1');
				$(el).tooltip('destroy').removeClass('wud-remove2 text-danger').addClass('wud-undo2 text-muted').attr('title','Do not remove').tooltip();
			}
		}
	},

	sortables: function() {
		if ($(list).hasClass('sortable')) {
			$(list).sortable({
				items: "> .item-form-group",
				handle: ".sort-handle",
				scroll: true,
				axis: "y",
				helper: "clone",
				placeholder: "sortable-placeholder col-sm-12",
				tolerance: "pointer",
				containment: "parent",
				revert: 300
			});
		}
	},

	followupControls: function() {
		if ($('#send-onboarding-modal').length) {
			$('#send-onboarding-modal .datepicker').datepicker({
	            weekStart: 1,
	            format: 'd M yyyy',
	            startDate: "today",
	            autoclose: true,
	            todayHighlight: true
	        });
		}
	},
};

$(function() {
	SimpleListEdit.init();
});
