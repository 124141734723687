var CasesList = {
    ajaxUrl: (window.location.pathname),
    customer_id: null,
    contact_id: null,
    include_activities: false,
    filtered_by_customer: false,
    filtered_by_contact: false,
    include_case_status: false,
    expanded_view: false,
    only_important_activities: false,
    case_status_id: 0,
    case_owner: 'me',
    open_activity_modal: null,
    placeholder_activity_modal_content: null,
    cases_list_needs_refresh: false,
    action_from_modal: false,
    cs_pg: 1,
    content_loading: false,
    expanded_case: null,
    date_filter: null,
    search_value: null,
    high_risk: null,
    show_deleted: null,
    upload_window_url: null,

    init: function() {
        var uri = new URI();
        CasesList.customer_id = (uri.segment(1) == 'customers' && uri.segment(2) == 'show') ? uri.segment(3) : null;
        CasesList.contact_id = (uri.segment(1) == 'contacts' && uri.segment(2) == 'show') ? uri.segment(3) : null;
        CasesList.only_important_activities = uri.hasQuery('only_important_activities') ? (uri.query(true).only_important_activities == 'true' ? true : false) : false;
        CasesList.open_activity_modal = uri.hasQuery('open_activity_modal') ? uri.query(true).open_activity_modal : null;
        CasesList.expanded_case = uri.hasQuery('expanded_case') ? uri.query(true).expanded_case : null;
        CasesList.include_activities = $('#cases-list-container').data('include-activities') == true ? true : false;
        CasesList.filtered_by_customer = $('#cases-list-container').data('filtered-by-customer') == true ? true : false;
        CasesList.filtered_by_contact = $('#cases-list-container').data('filtered-by-contact') == true ? true : false;
        CasesList.include_case_status = $('#cases-list-container').data('include-case-status') == true ? true : false;
        CasesList.expanded_view = $('#cases-list-container').data('expanded-view') == true ? true : false;
        CasesList.case_owner = uri.hasQuery('case_owner') ? uri.query(true).case_owner : ( $('#cases-list-container').data('case-owner') != '' ? $('#cases-list-container').data('case-owner') : 'me' );
        CasesList.date_filter = uri.hasQuery('date_filter') ? uri.query(true).date_filter : null;
        CasesList.search_value = uri.hasQuery('search_value') ? uri.query(true).search_value : null;
        CasesList.high_risk = uri.hasQuery('high_risk') ? uri.query(true).high_risk : null;
        CasesList.show_deleted = uri.hasQuery('show_deleted') ? uri.query(true).show_deleted : null;

        CasesList.scrollSpy();
		$(window).scroll(CasesList.scrollSpy);

		$('#cases-list-container').on('click', '.load-more', CasesList.loadOlderCases);

        $('#case-status-filter-label > span').text(
            $('#case-status-filter-options li.active a').text().toLowerCase()
        );

        $('#cases-date-filter-label > span').text(
            $('#cases-date-filter-options li.active a').text().toLowerCase()
        );

        $('#case-status-filter-options').on('click','li:not(.active) a', function(e) {
            CasesList.caseStatusFilter(e.target);
        })

        // $('#case-keyword-filter-options [name=search_value]')

        $('#dashboard-cases-summary').on('click','a:not(.active)', function(e) {
            var $lnk = $(this);
            $('#case-status-filter-options li a[data-case-status-id="'+$lnk.data('case-status-id')+'"]').click();
            $lnk.addClass('active').siblings().removeClass('active');
        })

        $('#case-owner-filter-options').on('click','li:not(.active) a', function(e) {
            CasesList.caseOwnerFilter(e.target);
        })

        if($('.cases-panel').length > 0) {
            $('.cases-panel #important_activities_filter').click(CasesList.importantActivitiesFilter);
        }

        if($('#cases-list-container').length > 0) {
            $('#high_risk_filter').click(CasesList.highRiskFilter);
        }

        $('#cases-list-container').on('shown.bs.collapse', '.cases-list', function(e) {
            CasesList.expanded_case = $(e.target).data('related-case');
            CasesList.setPageQueryString();
        });

        $('#cases-list-container').on('hide.bs.collapse', '.cases-list', function(e) {
            CasesList.expanded_case = null;
            CasesList.setPageQueryString();
        });

        $('.refinements').on('click', '#cases-date-filter-options li:not(.active) a', function(e) {
            CasesList.refineCasesByDate(e.target);
        });

        $('#high-risk').change(function() {
            CasesList.high_risk = $(this).prop('checked');
            CasesList.reloadCases();
        });
        
        $('#show-deleted').change(function() {
            CasesList.show_deleted = $(this).prop('checked');
            CasesList.reloadCases();
        });

        $('#keyword_filter').on('click', CasesList.toggleKeywordFilter);

        $('#search-value').on('input', function() {
            clearTimeout(App.isTyping);

            var sentence_line_filter = $(this).closest('.case-keyword-filter');
            if ($(sentence_line_filter).length > 0)
            {
                var search_value_label = $('#search-value').val() != '' ? $('#search-value').val() : 'keyword';
                $(sentence_line_filter).find('#case-keyword-filter-label span').html(search_value_label);
            }

			App.isTyping = setTimeout(function() {
                CasesList.reloadCasesAfterSearchingByKeyword();
            }, 700);
        });

        $('#search-cases').click(function(e) {
            e.preventDefault();
            e.stopPropagation();

            var sentence_line_filter = $(this).closest('.case-keyword-filter');
            if ($(sentence_line_filter).length > 0)
            {
                var search_value_label = $('#search-value').val() != '' ? $('#search-value').val() : 'keyword';
                $(sentence_line_filter).find('#case-keyword-filter-label span').html(search_value_label);
            }

            CasesList.reloadCasesAfterSearchingByKeyword();
        });

        if (CasesList.search_value) {
            $('#search-value').val(CasesList.search_value);
        }

        $('#cases-list-container').on('click', '.activity-item-note-more a', function(e) {
            e.preventDefault();
            CasesList.swapHiddenVisibleActivityContent(e.currentTarget);
            $(this).blur();
        });

        $('#cases-list-container').on('shown.bs.collapse', '.cases-list', function(e) {
            CasesList.setupShowMoreLinksForActivityContent();
        });
    },

    setPageQueryString: function() {
        var uri = new URI();
        uri.removeSearch('page');
        uri.removeSearch('case_status_id');
        uri.removeSearch('case_owner');
        uri.removeSearch('open_activity_modal');
        uri.removeSearch('expanded_case');
        uri.removeSearch('date_filter');
        uri.removeSearch('search_value');
        uri.removeSearch('high_risk');
        uri.removeSearch('show_deleted');
        qs = uri.removeSearch('only_important_activities');
        
        if (CasesList.case_status_id != 0) {
            uri.setSearch('case_status_id', CasesList.case_status_id);
        }
        if (CasesList.case_owner != 'me') {
            uri.setSearch('case_owner', CasesList.case_owner);
        }
        if (CasesList.only_important_activities) {
            qs = uri.addSearch('only_important_activities', 'true');
        }
        if (CasesList.open_activity_modal)
        {
            qs = uri.setSearch('open_activity_modal', CasesList.open_activity_modal);
        }
        if (CasesList.expanded_case)
        {
            qs = uri.setSearch('expanded_case', CasesList.expanded_case);
        }
        if (CasesList.date_filter)
        {
            qs = uri.setSearch('date_filter', CasesList.date_filter);
        }
        if (CasesList.search_value)
        {
            qs = uri.setSearch('search_value', CasesList.search_value);
        }
        if (CasesList.high_risk)
        {
            qs = uri.setSearch('high_risk', CasesList.high_risk);
        }
        if (CasesList.show_deleted)
        {
            qs = uri.setSearch('show_deleted', CasesList.show_deleted);
        }

        window.history.replaceState({}, null, qs);
    },

    reloadCases: function() {
        var spinner = Utils.spinner
            altSpinnerOpts = {
                lines: 13, // The number of lines to draw
                length: 3, // The length of each line
                width: 2, // The line thickness
                radius: 6, // The radius of the inner circle
                corners: 0.3, // Corner roundness (0..1)
                rotate: 0, // The rotation offset
                direction: 1, // 1: clockwise, -1: counterclockwise
                color: '#000', // #rgb or #rrggbb or array of colors
                speed: 1, // Rounds per second
                trail: 100, // Afterglow percentage
                shadow: false, // Whether to render a shadow
                hwaccel: false, // Whether to use hardware acceleration
                className: 'spinner', // The CSS class to assign to the spinner
                zIndex: 2e9, // The z-index (defaults to 2000000000)
                top: '50%', // Top position relative to parent
                left: '50%' // Left position relative to parent
            },
            altSpinner = new Spinner(altSpinnerOpts).spin()

        $('#cases-list-container .ajaxloader').fadeIn().append(spinner.el);

        if ($('#dashboard-cases-summary').length) {
            $('#dashboard-cases-summary .ajaxloader').fadeIn().append(altSpinner.el);
            CasesList.reloadCasesSummary();
        }

        $.ajax({
            url: '/admin/case_instances/ajaxGenerateCasesList',
            type: 'POST',
            data: {
                'action'                            : 'reloadCases',
                'include_activities'                : CasesList.include_activities,
                'filtered_by_customer'              : CasesList.filtered_by_customer,
                'filtered_by_contact'               : CasesList.filtered_by_contact,
                'include_case_status'               : CasesList.include_case_status,
                'expanded_view'                     : CasesList.expanded_view,
                'only_important_activities'         : CasesList.only_important_activities,
                'case_status_id'                    : CasesList.case_status_id,
                'customer_id'                       : CasesList.customer_id,
                'contact_id'                        : CasesList.contact_id,
                'case_owner'                        : CasesList.case_owner,
                'expanded_case'                     : CasesList.expanded_case,
                'date_filter'                       : CasesList.date_filter,
                'search_value'                      : CasesList.search_value,
                'high_risk'                         : CasesList.high_risk,
                'show_deleted'                      : CasesList.show_deleted
            },
            success: function(data) {
                CasesList.setPageQueryString();
                $('#cases-list-container').html(data);
                CasesList.cs_pg = 1;
            },
            error: function() {
            }
        });
    },

    reloadCasesAfterSearchingByKeyword: function() {
        CasesList.search_value = $('#search-value').val() != '' ? $('#search-value').val() : null;
        CasesList.reloadCases();
    },

    toggleKeywordFilter: function(e) {
        e.preventDefault();

        var filter = $('.case-keyword-filter-wrapper'),
            filter_visible = !$(filter).hasClass('hidden');

        if (filter_visible) {

            $(filter).find('#search-value').val('');
            $(filter).find('#case-keyword-filter-label span').html('keyword');
            $(filter).addClass('hidden');

            if (CasesList.search_value && CasesList.search_value != '') {
                CasesList.search_value = null;
                CasesList.reloadCases();
            }

            $(this).blur();
        }
        else {
            $(filter).removeClass('hidden');
            setTimeout(function() {
                $(filter).find('.case-keyword-filter').addClass('open');
                $(filter).find('#search-value').focus();
            }, 200);
        }

    },

    reloadCasesSummary: function() {
        $.ajax({
            url: '/admin/dashboard/index',
            type: 'POST',
            data: {
                'action'                            : 'reloadCasesSummary',
                'include_activities'                : CasesList.include_activities,
                'filtered_by_customer'              : CasesList.filtered_by_customer,
                'filtered_by_contact'               : CasesList.filtered_by_contact,
                'include_case_status'               : CasesList.include_case_status,
                'expanded_view'                     : CasesList.expanded_view,
                'only_important_activities'         : CasesList.only_important_activities,
                'case_status_id'                    : CasesList.case_status_id,
                'customer_id'                       : CasesList.customer_id,
                'contact_id'                        : CasesList.contact_id,
                'case_owner'                        : CasesList.case_owner,
                'expanded_case'                     : CasesList.expanded_case,
                'date_filter'                       : CasesList.date_filter,
                'search_value'                      : CasesList.search_value,
                'high_risk'                         : CasesList.high_risk
            },
            success: function(data) {
                $('#dashboard-cases-summary').html(data);
            },
            error: function() {
            }
        });
    },

    caseStatusFilter: function(lnk) {
        var $link = $(lnk),
            spinner = Utils.spinner;

        $('#cases-list-container .ajaxloader').fadeIn().append(spinner.el);

        CasesList.case_status_id = $link.data('case-status-id');
        CasesList.reloadCases();

        $('#case-status-filter-label > span').text($link.text().toLowerCase());
        $link.parent('li').addClass('active').siblings().removeClass('active');
    },

    caseOwnerFilter: function(lnk) {
        var $link = $(lnk),
            spinner = Utils.spinner;

        $('#cases-list-container .ajaxloader').fadeIn().append(spinner.el);

        CasesList.case_owner = $link.data('case-owner');
        CasesList.reloadCases();

        $('#case-owner-filter-label > span').text($link.text().toLowerCase());
        $link.parent('li').addClass('active').siblings().removeClass('active');
    },

    importantActivitiesFilter: function() {
        var $btn = $(this),
            spinner = Utils.spinner;

        $('#cases-list-container .ajaxloader').fadeIn().append(spinner.el);

        CasesList.only_important_activities = !CasesList.only_important_activities;
        CasesList.reloadCases();

        if (!CasesList.only_important_activities)
        {
            $btn.find('i.fa').removeClass('fa-star').addClass('fa-star-o');
        }
        else
        {
            $btn.find('i.fa').removeClass('fa-star-o').addClass('fa-star');
        }
    },

    highRiskFilter: function() {
        var $btn = $(this),
            spinner = Utils.spinner;

        $('#cases-list-container .ajaxloader').fadeIn().append(spinner.el);

        CasesList.high_risk = ! CasesList.high_risk;
        CasesList.reloadCases();

        if (!CasesList.high_risk)
        {
            $btn.find('i.fa').addClass('text-danger').parent('button').attr('data-original-title', 'Show only high risk cases').tooltip('show');
        }
        else
        {
            $btn.find('i.fa').removeClass('text-danger').parent('button').attr('data-original-title', 'Show all cases').tooltip('show');
        }
    },

    scrollSpy: function() {
        if (CasesList.content_loading) {
            return;
        }
        var scrollPos = $(document).scrollTop();
    	CasesList.trackPaginatorPosition(scrollPos);
    },

    trackPaginatorPosition: function(scrollPos) {
    	var paginator = $('#cases-list-container .load-more');

    	if ($(paginator).length) {
	    	var paginatorBottomPos = $(paginator).offset().top - $(window).height() + 50;
            if (scrollPos  >= paginatorBottomPos) {
                $(paginator).trigger('click');
            }
	    }
    },

	loadOlderCases: function(e) {
        e.preventDefault();

        var btn = $(this),
            spinner = Utils.spinner;

        CasesList.content_loading = true;
        CasesList.cs_pg++;

        var l = $(btn).ladda();
        l.ladda('start');

        $.ajax({
            url: '/admin/case_instances/ajaxGenerateCasesList',
            type: 'POST',
            data: {
                'action'                            : 'reloadCases',
                'include_activities'                : CasesList.include_activities,
                'filtered_by_customer'              : CasesList.filtered_by_customer,
                'filtered_by_contact'               : CasesList.filtered_by_contact,
                'include_case_status'               : CasesList.include_case_status,
                'only_important_activities'         : CasesList.only_important_activities,
                'case_status_id'                    : CasesList.case_status_id,
                'customer_id'                       : CasesList.customer_id,
                'case_owner'                        : CasesList.case_owner,
                'cs_pg'                             : CasesList.cs_pg,
                'high_risk'                         : CasesList.high_risk
            },
            success: function(data) {
                $(btn).remove();
                $('#cases-list-container').find('.cases-list').append($(data).filter('.cases-list').html());
                CasesList.content_loading = false;
            },
            error: function() {
            }
        });
	},

    refineCasesByDate: function(lnk) {
        var $link = $(lnk),
            spinner = Utils.spinner;

        $('#cases-list-container .ajaxloader').fadeIn().append(spinner.el);

        CasesList.date_filter = $link.data('date-filter') ? $link.data('date-filter') : null;
        CasesList.reloadCases();

        $('#cases-date-filter-label > span').text($link.text().toLowerCase());
        $link.parent('li').addClass('active').siblings().removeClass('active');
    },

    setupShowMoreLinksForActivityContent: function() {
        $('.activity-item-note').each(function() {
            var $contentContainer = $(this),
                $showMoreContainer = $contentContainer.siblings('.activity-item-note-more');

            if ($contentContainer.outerHeight() >= 150) {
                $showMoreContainer.removeClass('hidden');
            }
        });
    },

    swapHiddenVisibleActivityContent: function(obj) {
        var $obj = $(obj),
            $contentContainer = $obj.parents('.activity-item-note-more').siblings('.activity-item-note');

        if ($obj.hasClass('show-more')) {
            $contentContainer.addClass('expanded');
            $obj.removeClass('show-more').addClass('show-less').find('span').text('Show less');
            $obj.find('i.fa').removeClass('fa-angle-double-down').addClass('fa-angle-double-up');
        }
        else {
            $contentContainer.removeClass('expanded');
            $obj.removeClass('show-less').addClass('show-more').find('span').text('Show more');
            $obj.find('i.fa').removeClass('fa-angle-double-up').addClass('fa-angle-double-down');
        }
    }
};

$(function() {
    CasesList.init();
});
