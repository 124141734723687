var EmailAddresses = {
    row_curr_highest_index: 1,

    init: function() {
        if ($('#email-address-panel-buttons').length > 0 && $('.email-address-row div.form-group input:last').length > 0) {
            if ($('.email-address-row div.form-group input:last').attr('name').match(/^emailaddresses\[(\d+)\].+$/)) {
                EmailAddresses.row_curr_highest_index = $('.email-address-row div.form-group input:last').attr('name').match(/^emailaddresses\[(\d+)\].+$/)[1];
            }
            else {
                EmailAddresses.row_curr_highest_index = 0;
            }
        }

        $('.email-addresses-panel').each(function() {
            EmailAddresses.displayNoRowsMessage(this);
        });

        $('body').on('click','#email-address-panel-buttons a.add', function(e) {
            var $lnk = $(this);
            e.preventDefault();
            EmailAddresses.addNewRow($lnk);
            $(this).tooltip('hide');
        });

        $('body').on('click', '.email-addresses a.remove-email-address', function(e) {
            var $lnk = $(this);
            e.preventDefault();

            EmailAddresses.removeRow($lnk);
        });
    },

    addNewRow: function(lnk) {
        var wrapper = $(lnk).closest('.email-addresses-panel'),
            $newRow = $(wrapper).find('.email-address-row-template').clone();

        $newRow.find('select').val('');
        $newRow.find('input').val('');
        $newRow.removeClass('email-address-row-template hidden').addClass('email-address-row');
        $newRow.find('select, input').removeAttr('disabled');

        if ($(wrapper).find('.email-address-row').length == 0) {
            $(wrapper).find('#no-rows-message').after($newRow);
            $(wrapper).find('#no-rows-message').remove();
        }
        else {
            $(wrapper).find('.email-address-row').last().after($newRow);
        }

        EmailAddresses.row_curr_highest_index++;
        $(wrapper).find('.email-address-row:last :input').each(function() {
            var $obj = $(this),
                newName = $obj.attr('name').replace(/^(emailaddresses\[)\d+(\].+)$/, '$1' + EmailAddresses.row_curr_highest_index + '$2');

            $obj.attr('name', newName);
            $obj.attr('id', newName);
        });
    },

    removeRow: function($lnk) {
        var wrapper = $(lnk).closest('.email-addresses-panel'),
            curRow = $lnk.parents('.email-address-row');
        curRow.remove();

        EmailAddresses.displayNoRowsMessage(wrapper);

        // nb don't reduce row_count so that array doesn't need re-indexing
    },

    displayNoRowsMessage: function(wrapper) {
        if ($(wrapper).find('.email-address-row').length == 0) {
            $(wrapper).find('#email-address-panel-buttons').before(
                '<div id="no-rows-message">To add an email address use the link below</div>'
            );
        }
    }
};

$(function() {
    EmailAddresses.init();
});
