var CustomersForm = {
    // contracts_row_curr_highest_index: 1,

    init: function() {
        // if ($('.add-customer-form').length > 0) {
        //     CustomersForm.setupContracts();
        //     CustomersForm.setupDatepicker();
        // }

        $('.modal .customer-form-wrapper .custom-form .submit-btn').on('click', function(e) {
            $(this).closest('form').submit();
        });
        $('.modal .customer-form-wrapper .custom-form').on('submit', CustomersForm.inModalSubmit);

        $('#lookup-modal').on('click', '.cancel-btn', function(e) {
            e.preventDefault();
            $('#lookup-modal').modal('hide');
        });

        $('#customer_type_id').change(function() {
            CustomersForm.showHideCustomerNumberRequired();
        });

        CustomersForm.showHideCustomerNumberRequired();
        CustomersForm.useParentContractsChanged();
        $('body').on('change', 'input[name=parent_id]', CustomersForm.parentCustomerChanged);
        $('body').on('change', 'input[name=use_parent_contracts]', CustomersForm.useParentContractsChanged);
    },

    // Show/ hide required on customer number group as necessary
    showHideCustomerNumberRequired: function() {
        var $obj = $('#customer_type_id'),
            $customer_number_group = $('#number').closest('.form-group');

        if ($obj.val() == $obj.data('customer-type-id')) {
            $customer_number_group.addClass('required');
        }
        else {
            $customer_number_group.removeClass('required');
        }
    },

    // Show/ hide the main contracts panel if necessary
    useParentContractsChanged: function() {
        var $chkbx = $('input[name=use_parent_contracts]'),
            $contractsPanel = $('#contracts-panel');

        if ($chkbx.is(':checked')) {
            $contractsPanel.addClass('hidden');
        }
        else {
            $contractsPanel.removeClass('hidden');
        }
    },

    // When a parent customer is selected, then make the 'use parent contracts' checkbox visible and active
    parentCustomerChanged: function() {
        var $parentContractsCheckbox = $('input[name=use_parent_contracts]'),
            $parentContractsGroup = $parentContractsCheckbox.closest('.form-group'),
            $useParentsCheckbox = $('input[name=use_parent_contracts]'),
            $contractsPanel = $('#contracts-panel');

        if ($(this).val() != '') {
            // Make checkbox active and ensure visible
            $parentContractsCheckbox.prop('disabled', false);
            $parentContractsGroup.removeClass('hidden');
        }
        else {
            // Make checkbox inactive (disabled) and hidden
            // No need to disable form fields within contracts as will exclude in the controller
            // (i.e. they will still be submitted wtih the form)
            $parentContractsCheckbox.prop('disabled', true);
            $parentContractsGroup.addClass('hidden');
        }

        // Any time the parent customer is changed, uncheck the use parents contracts checkbox
        // and run check to see if contracts should be showing (they should)
        $useParentsCheckbox.prop('checked', false);
        CustomersForm.useParentContractsChanged();
    },

    inModalSubmit: function(e) {
        e.preventDefault();

        var formData = $('.modal .customer-form-wrapper .custom-form').serializeArray();

        $.ajax({
            url: '/admin/customers/add',
            type: 'POST',
            data: formData,
            success: function(data) {
                if (data.hasOwnProperty('success')) {
                    $('#lookup-modal').modal('hide');
                    FieldTypeahead.updateTypeaheadFromModal('newCustomer', data.customer_id);
                }
                else {
                    $('#lookup-modal .modal-body .contents').html(data);
                    FieldTypeahead.init();
                }
            },
            error: function() {
            }
        });
    },

    setupContracts: function() {
        if ($('.contract-row').length > 0) {
            if ($('.contract-row div.form-group input:last').attr('name').match(/^contracts\[(\d+)\].+$/)) {
                CustomersForm.contracts_row_curr_highest_index = $('.contract-row div.form-group input:last').attr('name').match(/^contracts\[(\d+)\].+$/)[1];
            }
        }
        else {
            CustomersForm.contracts_row_curr_highest_index = 0;
        }

        CustomersForm.displayNoContractsRowsMessage();

        $('#contracts-panel-buttons').on('click','a.add', function(e) {
            e.preventDefault();
            CustomersForm.addNewContractRow();
        });

        $('.contracts').on('click', 'a.remove-contract', function(e) {
            var $lnk = $(this);
            e.preventDefault();

            CustomersForm.removeContractsRow($lnk);
        });

        $('.contracts').on('click', 'a.expand-contract-details', function(e) {
            var $lnk = $(this),
                $label = $('.expand-label', $lnk),
                $icon = $('.fa', $lnk);

            e.preventDefault();

            $lnk.siblings('.expanded-contract-details').collapse('toggle');
            $label.toggleClass('more less');
            $icon.toggleClass('fa-chevron-down fa-chevron-up');

            if ($label.hasClass('more')) $label.text('More details');
            if ($label.hasClass('less')) $label.text('Hide details');

            $('a.expand-contract-details:visible').not(this).each(function() {
                var $lnk = $(this),
                    $label = $('.expand-label', $lnk),
                    $icon = $('.fa', $lnk);

                $label.removeClass('less').addClass('more');
                $icon.removeClass('fa-chevron-up').addClass('fa-chevron-down');

                if ($label.hasClass('more')) $label.text('More details');
                if ($label.hasClass('less')) $label.text('Hide details');
            });
        });

        $('.contracts').on('show.bs.collapse', '.collapse', function(e) {
            $('.contract-row .collapse').not(e.target).removeClass('in');
        });
    },

    addNewContractRow: function() {
        // Clone template row, change classes, and enable first two inputs
        var $newRow = $('.contract-row-template').clone();
        $newRow.find('select').val('');
        $newRow.find('input').val('');
        $newRow.removeClass('contract-row-template hidden').addClass('contract-row');
        $newRow.find(':input').removeAttr('disabled');
        $newRow.find('.date-duration, div.end-date input').prop('disabled', true);

        CustomersForm.collapseAllOpenContracts();

        // Insert row
        if ($('.contract-row').length == 0) {
            $('#no-rows-message').after($newRow);
            $('#no-rows-message').remove();
        }
        else {
            $('.contract-row').last().after($newRow);
        }

        // Update the name elements e.g. from [0] to [1]
        CustomersForm.contracts_row_curr_highest_index++;
        $('.contract-row:last :input[name]').each(function() {
            var $obj = $(this),
                newName = $obj.attr('name').replace(/^(contracts\[)\d+(\].+)$/, '$1' + CustomersForm.contracts_row_curr_highest_index + '$2');

            $obj.attr('name', newName);
            $obj.attr('id', newName);
        });

        // Setup the date pickers now a new row is in the dom
        CustomersForm.setupDatepicker();
        FieldTypeahead.init();
        Utils.setupPlaceholderForDisabledSelects();
    },

    removeContractsRow: function($lnk) {
        var curRow = $lnk.parents('.contract-row');
        curRow.remove();

        CustomersForm.displayNoContractsRowsMessage();

        // nb don't reduce row_count so that array doesn't need re-indexing
    },

    displayNoContractsRowsMessage: function() {
        if ($('.contract-row').length == 0 && $('#contracts-panel-buttons a.add').length > 0) {
            $('#contracts-panel-buttons').before(
                '<div id="no-rows-message">To add a contract use the link below</div>'
            );
        }
    },

    collapseAllOpenContracts: function() {
        var $rows = $('.contract-row');

        $rows.each(function() {
            var $label = $('.expand-label', $(this)),
                $icon = $('a.expand-contract-details .fa', $(this));

            $(this).find('.expanded-contract-details').removeClass('in');
            $label.removeClass('less').addClass('more').text('More details');
            $icon.removeClass('fa-chevron-up').addClass('fa-chevron-down');
        });
    },

    setupDatepicker: function() {
        // Add datepicker to all inputs with date class
        if ($('.date-field .input-group.date').length && $.isFunction($.fn.datepicker)) {
            $('.date-field .input-group.date').datepicker({
                format: "dd/mm/yyyy",
                todayBtn: true,
                container: $('.date-field .input-group.date').closest('.custom-form'),
                autoclose: true,
                todayHighlight: true
            });
        }

        // On change of start date...
        $('.date-field .input-group.date.start-date').datepicker().on('changeDate', function(e) {
            var $row = $(e.currentTarget).parents('.contract-row'),
                relEndDatepicker = $row.find('.input-group.date.end-date'),
                durationVal = $row.find('.date-duration').val(),
                startDate = $(this).datepicker('getDate'),
                momentStartDate = moment($(this).datepicker('getDate')),
                startDatePlusOne = new Date();

            // ... enable the duration and end date fields
            $row.find('.date-duration, .end-date input').removeAttr('disabled');

            // ... disable 'earlier' dates
            startDatePlusOne.setDate(startDate.getDate() + 1);
            relEndDatepicker.datepicker('setStartDate', startDatePlusOne);

            // ... if duration alread set, then update end date
            if (durationVal != '' && durationVal != 'custom') {
                duration = moment.duration(durationVal);
                relEndDatepicker.datepicker('update', momentStartDate.add(duration).toDate());
            }

            // ... clear 'invalid' end dates previously set
            if (relEndDatepicker.datepicker('getDate') && relEndDatepicker.datepicker('getDate') <= startDate) {
                relEndDatepicker.datepicker('clearDates');
            }

            Utils.setupPlaceholderForDisabledSelects();
        });

        // On change of the duration...
        $('.date-duration').change(function() {
            var $obj = $(this),
                val = $obj.val(),
                relStartDatepicker = $obj.parents('.contract-row').find('.input-group.date.start-date'),
                relEndDatepicker = $obj.parents('.contract-row').find('.input-group.date.end-date');

            // ... update the end date (based on the value of the select)
            if (relStartDatepicker.datepicker('getDate') && val != '' && val != 'custom') {
                var momentStartDate = moment(relStartDatepicker.datepicker('getDate')),
                    duration = moment.duration(val);

                relEndDatepicker.datepicker('update', momentStartDate.add(duration).toDate());
            }

        });

        // On change of end date...
        $('.date-field .input-group.date.end-date').datepicker().on('changeDate', function(e) {
            var $row = $(e.currentTarget).parents('.contract-row');

            // ... ensure that duration field is set to 'custom'
            $row.find('.date-duration').val('custom');
        });

    },
};

$(function() {
    CustomersForm.init();
});
