var CRMDocuments = {
    case_id: null,
    customer_id: null,
    activity_owner: 'me',
    show_important: false,
    activity_type_id: 0,
    is_customer_library: false,

    init: function() {
        if (window.opener) {
            if ($('.upload-window', window.opener.document).length) {
                if ($('#upload-form').length) {
                    var originatingLinkClass = '.upload-window';
                    if ($('#upload-form').hasClass('library')) {
                        originatingLinkClass = '.upload-window.library';
                        CRMDocuments.is_customer_library = true;
                    }

                    $('#upload-form').attr('action', $(originatingLinkClass, window.opener.document).data('windowurl'));

                    CRMDocuments.case_id = $(originatingLinkClass, window.opener.document).data('case-id');
                    CRMDocuments.customer_id = $(originatingLinkClass, window.opener.document).data('customer-id');
                    CRMDocuments.activity_owner = $(originatingLinkClass, window.opener.document).data('activity-owner');
                    CRMDocuments.show_important = $(originatingLinkClass, window.opener.document).data('show-important');
                    CRMDocuments.activity_type_id = $(originatingLinkClass, window.opener.document).data('activity-type-id');
                }
            }
        }

        $('#upload-form').on('click', '.close-window', function() {
            window.close();
        });

        $('#upload-form').on('click', '.upload-submit-btn', function() {
            $('.upload-submit-btn').attr('disabled', true);
            CRMDocuments.submitForm();
        });

        $('#upload-form').on('change', ':file', function() {
            var input = $(this),
                numFiles = input.get(0).files ? input.get(0).files.length : 1,
                label = input.val().replace(/\\/g, '/').replace(/.*\//, '');

            input.trigger('fileselect', [numFiles, label]);
        });

        $(':file').on('fileselect', function(event, numFiles, label) {
            var input = $(this).parents('.input-group').find(':text'),
                log = numFiles > 1 ? numFiles + ' files selected' : label;

            if (input.length) {
                input.val(log);
            }
        });
    },

    submitForm: function() {
        var spinner = Utils.spinner;
        $('#upload-window-container .ajaxloader').fadeIn().append(spinner.el);

        var formData = new FormData($('#upload-form')[0]);

        $.ajax({
            url: $('#upload-form').attr('action'),
            type: 'POST',
            data: formData,
            async: false,
            success: function (data) {
                if (!data.success) {
                    $('#uploader-messages p').remove();
                    $('#uploader-messages').append('<p>'+data.message+'</p>').show();
                    $('#upload-window-container .ajaxloader').remove();
                    $('.upload-submit-btn').removeAttr('disabled');
                }

                if (data.success) {
                    if (CRMDocuments.is_customer_library && $('#document-library-contents-container', window.opener.document).length) {
                        CRMDocuments.refreshDocumentsList();
                    }

                    if (!CRMDocuments.is_customer_library && $('#activity-list-container', window.opener.document).length) {
                        if (CRMDocuments.case_id) CaseActivities.case_id = CRMDocuments.case_id;
                        if (CRMDocuments.customer_id) CaseActivities.customer_id = CRMDocuments.customer_id;
                        if (CRMDocuments.activity_owner != 'everyone') CaseActivities.activity_owner = CRMDocuments.activity_owner;
                        if (CRMDocuments.show_important != 0) CaseActivities.show_important = CRMDocuments.show_important;
                        if (CRMDocuments.activity_type_id != 0) CaseActivities.activity_type_id = CRMDocuments.activity_type_id;

                        CaseActivities.reloadActivities(window);
                        setTimeout(function() { window.close(); }, 1000);
                    }
                }
            },
            cache: false,
            contentType: false,
            processData: false
        });
    },

    refreshDocumentsList: function() {
        var spinner = Utils.spinner;

        $('#document-library-contents-container .ajaxloader').fadeIn().append(spinner.el);

        $.ajax({
            url: window.opener.location.pathname,
            type: 'POST',
            data: {
                'action'    : 'reloadDocuments'
            },
            success: function(data) {
                $('#document-library-contents-container', window.opener.document).html(data);
                $('#uploader-success-message', window.opener.document).append('<p>Upload succesful</p>').show();
                window.close();
            },
            error: function() {
            }
        });
    },

    closeWindow: function() {
        window.close();
    }
};


$(function() {
    CRMDocuments.init();
});
