var Template = {
    ajaxUrl: (window.location.pathname) + '/xhr-call',
    globalLoadingSpinner: null,
    loadingElements: {},
    sortables: {},
    tabsLocked: true,
    changeLog: function (message, type) {
        if (!type)
            type = 'success';
        var html = "<li class='log " + type + "'>" + message + "</li>", timeout = 5000;
        $('#change-log').prepend(html);
        var ele = $('#change-log li:first-child');
        setTimeout(function () {
            $(ele).fadeOut();
        }, timeout);
    },
    getSortableIndex: function (selector, dataId) {
        var order = [];
        if (!selector)
            return false;
        // String or jquery elements
        if (typeof selector == 'string') {
            selector = $(selector);
        }

        selector.each(function (i) {
            order.push($(this).attr(dataId ? dataId : 'data-id'));
        });
        return order;
    },
    init: function () {
        Template.loading('init');
        Template.initUnselectedGenericContent();
        Template.initTabs();
        Template.tabSwapper();

        // Private
        var resizeUnselectedGenericContent = function () {
            var marginTop = $('.awesome-container').css('margin-top').replace(/[^0-9]+/g, ''),
                    val = $('html').height() - $('#top-navbar').height();

            $('.awesome-container').css({
                height: val - marginTop,
                'min-height': val - marginTop
            });

            $('.row .col-md-9').css({
                height: val,
                'min-height': val
            });
        };

        var resizeFilter = function () {
            // var val = $('.filter-awesome-container').parent().width() - 15 - 20;
            // $('.filter-awesome-container').css('width', val + 'px');
        };

        $(window).resize(function () {
            resizeFilter();
            resizeUnselectedGenericContent();
            Template.tabSwapper();
        });

        resizeFilter();
        resizeUnselectedGenericContent();

        Template.filterBlocks($('#filter_template_blocks'));
        $('.unselected-generic-content').on('keyup', '#filter_template_blocks', Template.filterBlocks);
        $('.unselected-generic-content').on('click', '.reload-unselected', function () {
            Template.initUnselectedGenericContent(true);
            $(this).addClass('busy');
            $('.unselected-generic-content #filter_template_blocks').val('').focus();
        });

        if ($('.tab-lock').hasClass('unlocked')) Template.tabsLocked = false;

        $('.tab-lock').on('click', function (e) {
            e.preventDefault();
            if (Template.tabsLocked) {
                $(this).addClass('unlocked').attr('title', 'Lock tabs').tooltip('destroy').tooltip();
                $('.row.template').addClass('unlocked').removeClass('locked');
                Template.tabsLocked = false;
                Template.changeLog('Tabs are now unlocked.');
            } else {
                $(this).removeClass('unlocked').attr('title', 'Unock tabs').tooltip('destroy').tooltip();
                $('.row.template').removeClass('unlocked').addClass('locked');
                Template.tabsLocked = true;
                Template.changeLog('Tabs are now locked.');
            }
            Template.tabSwapper();
        });

        $('body').on('click', '.insert-tab-variable', Template.showTabVariablePicker);
        Template.lookupVariables();
        $('#variable_fields').on('change','#variable-section, #variable-field, #variable-product, #variable-product-flag', Template.lookupVariables);
        $('#variable_fields').on('click','.insert-variable', Template.insertVariable);

        Template.loading('init', true);
    },
    filterBlocks: function (el) {
        el = typeof el.type === 'undefined' ? el : $(this);
        var search_string = $(el).val().toLowerCase().trim();
        if (search_string == '') {
            $(".unselected-generic-content .widgets-dock li").removeClass('hidden');
        } else {
            $(".unselected-generic-content .widgets-dock li:not(:contains('" + search_string + "'))").each(function () {
                $(this).addClass('hidden');
            });
            $(".unselected-generic-content .widgets-dock li:contains('" + search_string + "')").each(function () {
                $(this).removeClass('hidden');
            });
        }

        $(".unselected-generic-content .widgets-dock").each(function () {
            if ($(this).find('.draggable-widget.hidden').length == $(this).find('.draggable-widget').length) {
                $(this).closest('.category-container').addClass('hidden');
            } else {
                $(this).closest('.category-container').removeClass('hidden');
            }
        });
    },
    initNameChange: function (e) {
        e.preventDefault();
        var form = $(this).closest('form');

        $.ajax(Template.ajaxUrl, {
            type: 'POST',
            data: {
                task: 'change_tab_name',
                tab: {
                    tab_id: form.find('[name="tab_id"]').val(),
                    tab_name: form.find('[name="tab_name"]').val(),
                    product_type: form.find('[name="product_type"]').val(),
                    variable_name: form.find('[name="variable_name"]').val()
                }
            },
            complete: function (r) {
                var json = r.responseJSON;

                $('.tiny[data-id="' + json.id + '"]').html(json.name).attr('data-product-id', json.product_type).attr('data-variable-name',json.variable_name);

                $('#modal-edit').modal('hide');
//                Template.loading('nameChange',true);
                Template.tabSwapper();
                Template.changeLog('Name Changed to ' + json.name);
            }
        });
    },
    initTabs: function () {
        // Initialise the tabs onclick event
        $('body').on('click', '.nav-tabs li:not(.disabled) a', function (e) {
            e.preventDefault();
            $(this).tab('show');


            // Find the opposing nav and select that one too
            var dataId = $(this).parent().attr('data-id');

            if ($(this).closest('.tab-pane').find('.nav-tabs li:not(.disabled)').length) {
                $('.tab-pane.active .nav-tabs li:not(.disabled)').removeClass('active');
                $('.tab-pane.active .nav-tabs li:not(.disabled)[data-id="' + dataId + '"]').addClass('active');
            } else {
                $('.nav-tabs-container .nav-tabs li:not(.disabled)').removeClass('active');
                $('.nav-tabs-container .nav-tabs li:not(.disabled)[data-id="' + dataId + '"]').addClass('active');
            }

        });

        // On tab show event to swap to mobile (or vice versa) if need be
        $('body').on('shown.bs.tab', '.nav-tabs li:not(.disabled) a', function (e) {
            Template.tabSwapper();
        });

        // Add modal listener and populate modal form
        $('body').on('click', 'span.edit', Template.modalify);
        // Modal form submit changes the name of the tab
        $('#modal-form').find('[type="submit"]').click(Template.initNameChange);

        // Add a new tab
        $('body').on('click', '.add-tab', function (e) {
            e.preventDefault();
            e.stopPropagation();

            Template.loading('addTab');

            var ele =   $('<li>' +
                        '<div class="tab-tools">' +
                            '<span class="edit wud-pencil"></span>' +
                            '<span class="move glyphicon glyphicon-move ui-sortable-handle"></span>' +
                        '</div>' +
                        '<a class="tab-button" data-toggle="tab">' +
                            '<span class="tiny" data-product-id="" data-variable-name="">New Tab</span>' +
                        '</a>' +
                    '</li>');

            var eleA = ele.find('a:not(.mobile-tab)'),
                    tinySpan = eleA.find('.tiny'),
                    parent = $(this).parent(),
                    parentId = $(this).closest('.sub-tab').length ? $(this).closest('.tab-pane').attr('data-id') : null,
                    documentId = $('#document-id').val() || null,
                    container = $(this).closest('.nav-tabs').parent().parent();

            eleA.attr('href', '');
            tinySpan.attr('data-product-id', '').html('New ' + (!documentId ? 'Tab' : 'Page'));

            var tabEle = '<div class="tab-pane fade">' +
                    '<div class="row">' +
                    '<ul class="connected-sortables ui-sortable">' +
                    (!parentId && !documentId ? '<a class="sub-tab-option" href="#">Make this a sub tab</a>' : '') +
                    '</ul>' +
                    '</div>' +
                    '</div>';
            $.ajax(Template.ajaxUrl, {
                type: "POST",
                data: {
                    task: 'new_tab',
                    parent_id: parentId,
                    document_id: documentId
                },
                complete: function (r) {
                    // Add new tab contents to both desktop and mobile tabs
                    container.find('.tab-content').first().append(tabEle);

                    var json = r.responseJSON, newTab = container.find('> .tab-content').first().find('> .tab-pane:last-child');
                    ele.attr('data-id', json.id);
                    ele.find('a').attr('href', '#panel-' + json.id);
                    tinySpan.attr('id', '').attr('data-id', json.id);
                    parent.before(ele);
                    newTab.attr('data-id', json.id).attr('id', 'panel-' + json.id);
                    Template.reloadOpposingNav(parentId ? parentId : null, function () {
                        Template.tabSortable(true);
                        Template.tabGenericContentSortable(true);
                        Template.tabSwapper();
                        Template.loading('addTab', true);
                        Template.changeLog('New ' + (!documentId ? 'tab' : 'page') + ' added');
                    });

                }
            });
        });

        // Drag tab onto delete functionality
        $('#remove-tab').droppable({
            accept: '.nav-tabs li',
            drop: function (e, ui) {
                var
                        remove = $(this),
                        eleData = '<span id=\'remove-spinner\' class=\'spinner-container\'></span>',
                        glyph = remove.find('.glyphicon'),
                        p = remove.find('p'),
                        draggableItem = $(ui.draggable),
                        container = draggableItem.closest('.nav-tabs').parent().parent(),
                        parentId = draggableItem.closest('.sub-tab-list').closest('.tab-pane').attr('data-id') || null,
                        spinnerOptions = {
                            left: 0,
                            length: 10,
                            lines: 16,
                            radius: 5,
                            width: 5
                        };

                if (!draggableItem.closest('.sub-tab').length && !(draggableItem.parent().find('li:not(.disabled)').length > 2)) {
                    // 2 because of the add tab li and :not(.disabled) doesn't seem to be working?
                    // return alert('Cannot delete the only item in this list');
                    Template.changeLog('Cannot delete the only tab item in this list', 'warning');
                    return false;
                }

                Template.loading('removeTab');

                var spin = new Spinner(spinnerOptions).spin(document.getElementById('remove-spinner'));

                // Close any open dropdown lists
                $('.dropdown.open').removeClass('open');

                // Prep the front end loaders
                remove.prepend(eleData);
                glyph.hide();
                p.html('Deleting');

                // Remove tab content
                $(draggableItem.find('a').attr('href')).remove();
                draggableItem.remove();

                var
                        parentNav = container.find('> div:not(.tab-content) .nav-tabs'),
                        parentTabs = container.find('> div.tab-content');

                parentNav.find('li:not(.disabled).active').removeClass('active');
                parentNav.each(function (i) {
                    $(this).find('li:not(.disabled)').first().addClass('active in');
                });

                parentTabs.find('> .tab-pane.active').removeClass('active');
                parentTabs.find('> .tab-pane').first().addClass('active in');

                // Ajax to remove tab from database
                $.ajax(Template.ajaxUrl, {
                    data: {
                        task: 'delete_tab',
                        tab: {
                            tab_id: draggableItem.attr('data-id')
                        }
                    },
                    type: 'POST',
                    complete: function (r) {
                        var json = r.responseJSON;
                        // Remove delete bar and show content options
                        glyph.show();
                        spin.stop();
                        $('#remove-spinner').remove();
                        p.html('Delete');

                        // console.log('parentId ', parentId);

                        if (json.html) {
                            $('.tab-pane[data-id="' + parentId + '"]').html(json.html);
                        }

                        // Reinit tab listeners
                        Template.reloadOpposingNav(parentId, function () {

                            // The tabSortable update function will run when a tab has removed, so no need to do tabSortable again
                            // Template.tabSortable(true);
                            Template.tabGenericContentSortable(true);
                            Template.tabSwapper();
                            Template.loading('removeTab', true);
                            Template.changeLog('Tab removed');
                            // Remove sub tabs option if there aren't any anymore
                        });
                    }
                });
            }
        });

        // Create/Remove Sub tabs
        $('body').on('click', '.sub-tab-option', Template.initSubTabs);

        // Remove generic content from tabs
        $('body').on('click', '.delete-tab-generic-content', function (e) {
            Template.loading('deleteGenericContent');

            var a = $(this);

            $.ajax(Template.ajaxUrl, {
                data: {
                    task: 'delete_generic_content',
                    tab_generic_content_id: $(this).closest('.panel').attr('data-tab-content-id')
                },
                type: 'POST',
                complete: function () {
                    a.closest('.panel').fadeOut(750, function () {
                        $(this).remove();
                    });

                    var count = a.closest('.tab-content').parent().find('>.nav-tabs').find('.active').find('.count');

                    if (count.length) {
                        var number = count.html().replace(/[^0-9]/g, '');
                        count.html('(' + (Number(number) - 1) + ')');
                    }

                    Template.initUnselectedGenericContent(true);
                    Template.loading('deleteGenericContent', true);
                }
            });
        });

        // Initial sortable setup
        Template.tabSortable();
        Template.tabGenericContentSortable();
    },
    initSubTabs: function (e) {
        e.preventDefault();
        Template.loading('initSubTabs');

        var tabPane = $(this).closest('.tab-pane');
        $.ajax(Template.ajaxUrl, {
            type: 'POST',
            data: {
                task: 'toggle_sub_tabs',
                tab_id: tabPane.attr('data-id')
            },
            complete: function (r) {
                var json = r.responseJSON;
                tabPane.html(json.html);
                Template.tabSortable(true);
                Template.tabGenericContentSortable(true);
                Template.tabSwapper();
                Template.loading('initSubTabs', true);
                Template.changeLog(json.sub_tab_id ? 'Sub tab added' : 'Sub tab removed');
            }
        });
    },
    initUnselectedGenericContent: function (reload) {
        Template.loading('widgetDock');

        var elements = $('.widgets-dock'), total = 0;

        if (reload) {
            elements.sortable().sortable('disable');
            $.ajax(Template.ajaxUrl, {
                data: {
                    task: 'reload_unselected_generic_content',
                    document_id: $('#document-id').val() || null
                },
                type: 'POST',
                complete: function (r) {
                    $('.unselected-generic-content').find('.awesome-container').html(r.responseJSON.html);
                    $('.unselected-generic-content .reload-unselected').removeClass('busy');
                    elements.sortable().sortable('destroy');
                    Template.initUnselectedGenericContent();
                    Template.filterBlocks($('#filter_template_blocks'));
                }
            });
            return;
        }

        if (!elements.length) {
            Template.loading('widgetDock', true);
            return;
        }

        elements.sortable({
            appendTo: $('body'),
            helper: "clone",
            scroll: false,
            revert: 300,
            connectWith: '.tab-pane ul.connected-sortables',
            create: function (e, ui) {
                total++;

                if (total === elements.length) {
                    Template.loading('widgetDock', true);
                }
            },
            remove: function (e, ui) {
                var item = $(this);
                if (!ui.item.closest('.tab-pane').attr('data-id')) {
                    return; //alert('Error');
                }

                Template.loading('widgetDock');

                $.ajax(Template.ajaxUrl, {
                    data: {
                        task: 'add_generic_content',
                        tab_id: ui.item.closest('.tab-pane').attr('data-id'),
                        generic_content_id: ui.item.attr('data-content-id'),
                        order: Template.getSortableIndex(ui.item.parent().find('.panel'), 'data-tab-content-id'),
                        new_index: ui.item.index()
                    },
                    type: 'POST',
                    complete: function (r) {
                        ui.item.attr('data-tab-content-id', r.responseJSON.tab_generic_content_id);
                        ui.item.find('.content-tools').show();
                        var count = ui.item.closest('.tab-content').parent().find('>.nav-tabs').find('.active').find('.count');
                        if (count.length) {
                            var number = count.html().replace(/[^0-9]/g, '');
                            count.html('(' + (Number(number) + 1) + ')');
                        }

                        if (item.find('li').length == 0) {
                            item.parent().remove();
                        }

                        Template.initUnselectedGenericContent(true);
                    }
                });
            }
        });
    },
    loading: function (index, finished) {
        var container = $('.ultimate-loader'), timeout = 5000; // Number of milliseconds before it automatically gets wiped

        if (finished) {
            if (this.loadingElements[index]) {
                delete this.loadingElements[index];
            }

            if (!Object.keys(this.loadingElements).length) {
                container.fadeOut('slow');
            }
            return;
        }

        this.loadingElements[index] = '1';

        if (!this.globalLoadingSpinner) {
            this.globalLoadingSpinner = new Spinner({
                lines: 13, // The number of lines to draw
                length: 3, // The length of each line
                width: 2, // The line thickness
                radius: 6, // The radius of the inner circle
                corners: 0.3, // Corner roundness (0..1)
                rotate: 0, // The rotation offset
                direction: 1, // 1: clockwise, -1: counterclockwise
                color: '#000', // #rgb or #rrggbb or array of colors
                speed: 1, // Rounds per second
                trail: 100, // Afterglow percentage
                shadow: false, // Whether to render a shadow
                hwaccel: false, // Whether to use hardware acceleration
                className: 'spinner', // The CSS class to assign to the spinner
                zIndex: 2e9, // The z-index (defaults to 2000000000)
                top: '25%', // Top position relative to parent
                left: '25%' // Left position relative to parent
//            }).spin(document.getElementById('global-loading-spinner'));
            }).spin(document.getElementById('ultimate-loading-spinner'));
        }

        container.fadeIn('fast');

//        setTimeout(function(){
//            Template.loading(index,true);
//        }, timeout);
    },
    modalify: function (e) {
        e.preventDefault();
        var form = $('#modal-form'), edit = $(this), textSpan = edit.closest('li').find('.tiny');

        form.find('[name="tab_id"]').val(textSpan.attr('data-id'));
        form.find('[name="tab_name"]').val(textSpan.html());
        form.find('.tab-visibility-container-wrapper').html('');

        $('#modal-form').find('.show-tab-visibility').off('click').on('click', function(e) {
            e.preventDefault();

            var btn = this,
                url = $(btn).attr('href') + textSpan.attr('data-id');

            $.ajax(url).done(function(html) {
                form.find('.tab-visibility-container-wrapper').html('<div id="customer-tab-visibility" class="in">'+ html +'</div>');
                TabVisibility.setupTabsVisibilityForm();
            });

        });

        $('#modal-edit').off('show.bs.modal');
        $('#modal-edit').on('show.bs.modal', function (e) {
            if (typeof textSpan.attr('data-variable-name') !== typeof undefined && textSpan.attr('data-variable-name') !== false) {
                form.find('[name=variable_name]').val(textSpan.attr('data-variable-name'));
            }
            else {
                form.find('[name=variable_name]').val('');
            }

            form.find('[name="tab_name"]').select().focus();
        });
        $('#modal-edit').modal('show');
    },
    reloadOpposingNav: function (subTabParentId, onComplete) {
        Template.loading('opposingNav');
        var className = '';

        if (subTabParentId) {
            if ($('#panel-' + subTabParentId + ' .sub-tab-list.hidden-xs').is(':visible')) {
                className = '.visible-xs';
            } else {
                className = '.hidden-xs';
            }

            if ($('#panel-' + subTabParentId + ' .sub-tab-list' + className).length) {
                $('#panel-' + subTabParentId + ' .sub-tab-list' + className).parent().load(
                        (window.location.pathname + ' #panel-' + subTabParentId + ' .sub-tab-list' + className),
                        function (r) {
                            onComplete(r);
                            Template.loading('opposingNav', true);
                        }
                );
            } else {
                onComplete();
                Template.loading('opposingNav', true);
            }
        } else {
            if ($('.nav-tabs' + className + ':not(.sub-tab-list).hidden-xs').is(':visible')) {
                className = '.visible-xs';
            } else {
                className = '.hidden-xs';
            }

            if ($('.nav-tabs' + className + ':not(.sub-tab-list)').length) {
                $('.nav-tabs' + className + ':not(.sub-tab-list)').parent().load(
                        (window.location.pathname + ' .nav-tabs' + className + ':not(.sub-tab-list)'),
                        function (r) {
                            onComplete(r);
                            Template.loading('opposingNav', true);
                        }
                );
            } else {
                onComplete();
                Template.loading('opposingNav', true);

            }
        }

    },
    tabGenericContentSortable: function (reload) {
        Template.loading('tabGenericContent');
        var elements = $('.tab-content:not(.locked) ul:not(.nav-tabs):not(li.disabled ul)'), total = 0;

        if (reload) {
            elements.sortable().sortable('destroy');
        }

        if (!elements.length) {
            Template.loading('tabGenericContent', true);
            return;
        }

        elements.sortable({
            scroll: false,
            revert: 300,
            create: function (e, ui) {
                total++;

                if (total === elements.length) {
                    Template.loading('tabGenericContent', true);
                }
            },
            update: function (e, ui) {
                elements.sortable().sortable('disable');
                $.ajax(Template.ajaxUrl, {
                    data: {
                        task: 'change_content_order',
                        order: Template.getSortableIndex(ui.item.closest('ul').find('li'), 'data-tab-content-id'),
                        tab_id: ui.item.closest('.tab-pane').attr('data-id')
                    },
                    type: 'POST',
                    complete: function (r) {
                        elements.sortable().sortable('enable');
                    }
                });
            }
        });
    },
    tabSortable: function (reload) {
        Template.loading('tabSortable');
        var elements = $('.nav-tabs'), total = 0;

        if (reload) {
            elements.sortable().sortable('destroy');
        }

        elements.sortable({
            items: 'li:not(.disabled)',
            handle: 'span.move',
            scroll: false,
            revert: 300,
            create: function (e, ui) {
                total++;

                if (total === elements.length) {
                    Template.loading('tabSortable', true);
                    Template.tabSwapper();
                }
            },
            start: function (e, ui) {
                $('#remove-tab').fadeIn('fast');
            },
            stop: function (e, ui) {
                $('#remove-tab').fadeOut('fast');
            },
            update: function (e, ui) {
                Template.loading('tabSortable');
//                elements.sortable('disable');
                $.ajax(Template.ajaxUrl, {
                    data: {
                        task: 'change_tab_order',
                        order: Template.getSortableIndex(ui.item.closest('.nav-tabs').find('li:not(.disabled)'))
                    },
                    type: 'POST',
                    complete: function (r) {
                        // console.log("RAND", ui.item.closest('.sub-tab').length ? ui.item.closest('.sub-tab').parent().attr('data-id') : null);
                        Template.reloadOpposingNav(
                                ui.item.closest('.sub-tab').length ? ui.item.closest('.sub-tab').parent().attr('data-id') : null,
                                function (rs) {
                                    Template.tabSortable(true);
                                }
                        );
                    }
                });
            }
        });
    },
    tabSwapper: function () {
        $('.nav-tabs.hidden-xs').each(function () {
            var total = 0, container = $(this).parent().parent();

            container.find('> div > .nav-tabs').parent().removeClass('hidden').addClass('block');

            $(this).find('> li').each(function () {
                total += $(this).width();
            });

            var hideClass = 'visible-xs';

            if (total >= container.width()) {
                hideClass = 'hidden-xs';
            }

            container.find('> div > .nav-tabs.' + hideClass).parent().removeClass('block').addClass('hidden');
        });
    },
    showTabVariablePicker: function(e) {
        e.preventDefault();

        modal = Utils.renderModal('#variables_modal');
        modal.modal();
    },
    lookupVariables: function() {
        var section = $('#variable-section').val(),
            field = $('#variable-field').val(),
            product = $('#variable-product').val(),
            product_flag = $('#variable-product-flag').val(),
            url = '/admin/generic-contents/variables'+(section?'/'+section:'/null')+(product?'/'+product:'/null')+(product_flag?'/'+product_flag:'/null')+(field?'/'+field:'');

        $.ajax(url).done(function(html) {
            $('.variables-wrapper').html(html);
            $('#variable_fields .input-group').addClass('form-inline').removeClass('input-group');
            $('#variable_fields .input-group-btn').removeClass('input-group-btn');
            $('#variable_fields select').attr('multiselect','multiselect').multiselect({buttonClass: 'btn btn-link'});
        });
    },

    insertVariable: function() {
        var form = $('#modal-form');

        var section = $('#variable-section').val(),
            field = $('#variable-field').val(),
            product = $('#variable-product').val(),
            product_flag = $('#variable-product-flag').val(),
            link_text = $('#variable-link-text').val();

        var variable = '{Insert'+(section!='Variable'?' '+section:'')+(product?' '+product:'')+(product_flag&&product_flag!='-1'?' '+product_flag:'')+(field!=''?' '+field:'')+(link_text?'['+link_text+']':'')+'}';

        form.find('[name="variable_name"]').val(variable);

        var modal = $(this).closest('.modal');
        modal.modal('hide');
    },
};
$(function () {
    if ($('.wrapper-template').length) {
        Template.init();
    }
});
