var CustomersFormContracts = {
    contracts_row_curr_highest_index: 0,

    init: function() {
        if ($('.add-customer-form').length > 0) {
            CustomersFormContracts.setupContracts();
            CustomersFormContracts.setupDatepicker();
        }
    },

    setupContracts: function() {
        // Set up index for contract[] fields
        if ($('.contract-row').length > 0) {
            if ($('.contract-row div.form-group input:last').attr('name').match(/^contracts\[(\d+)\].+$/)) {
                CustomersFormContracts.contracts_row_curr_highest_index = $('.contract-row div.form-group input:last').attr('name').match(/^contracts\[(\d+)\].+$/)[1];
            }
        }
        else {
            CustomersFormContracts.contracts_row_curr_highest_index = 0;
        }

        // Add a new contract
        $('#contracts-panel-buttons').on('click','a.add-contract', function(e) {
            e.preventDefault();
            CustomersFormContracts.addNewContractRow();
        });

        // Remove a contract
        $('.contracts, .cancelled-contracts').on('click', 'a.remove-contract', function(e) {
            var $lnk = $(this);
            e.preventDefault();

            CustomersFormContracts.removeContractsRow($lnk);
        });
        $('.contracts, .cancelled-contracts').on({
            mouseenter: function() {
                $(this).parents('.contract-row').addClass('active');
            },
            mouseleave: function() {
                $(this).parents('.contract-row').removeClass('active');
            }
        }, 'a.remove-contract');

        // Update total contract cost
        $('.contracts, .cancelled-contracts').on('change', '.contract-cost', function() {
            CustomersFormContracts.sanitizeCost($(this));
            CustomersFormContracts.updateTotalContractCost();
        });

        $('.contracts, .cancelled-contracts').find('.contract-source-select').each(function() {
            CustomersFormContracts.toggleSeminarsField.bind(this)();
        });

        $('.contracts, .cancelled-contracts').find('.contract-status-select').each(function() {
            CustomersFormContracts.toggleCancellationReasonField.bind(this)();
        });

        $('.contracts, .cancelled-contracts').on('change', '.contract-source-select', CustomersFormContracts.toggleSeminarsField);

        $('.contracts, .cancelled-contracts').on('change', '.contract-status-select', CustomersFormContracts.toggleCancellationReasonField);

        CustomersFormContracts.displayNoContractsRowsMessage();
        CustomersFormContracts.setupProducts();
    },

    toggleSeminarsField: function() {
        source1 = $(this).closest('.contract-row').find('.contract-source-select.source-1'),
        source2 = $(this).closest('.contract-row').find('.contract-source-select.source-2'),
        val1 = $(source1).find('option:selected').html(),
        val2 = $(source2).find('option:selected').html();

        if (val1 == 'Seminar' || val2 == 'Seminar') {
            $(this).closest('.contract-row').find('.contract-source-seminar-wrapper').removeClass('hidden');
        } else {
            $(this).closest('.contract-row').find('.contract-source-seminar-wrapper').addClass('hidden');
        }
    },

    toggleCancellationReasonField: function() {
        var val = $(this).find('option:selected').html();

        if (val == 'Cancelled') {
            $(this).closest('.contract-row').find('.contract-cancellation-reason-wrapper').removeClass('hidden');
        } else {
            $(this).closest('.contract-row').find('.contract-cancellation-reason-wrapper').addClass('hidden');
        }
    },

    sanitizeCost: function($obj) {
        var val = parseFloat($obj.val()).toFixed(2);

        if (!isNaN(val)) {
            $obj.val(val);
        }
    },

    updateTotalContractCost: function() {
        var total_cost = null;

        $('.contract-cost:enabled').each(function(i, v) {
            var $obj = $(v);

            if (!isNaN(parseFloat($obj.val()))) {
                total_cost += parseFloat($obj.val());
            }
            else {
                total_cost = null;
                return false;
            }
        });

        if (total_cost) {
            $('input[name="contract_total_cost"]').val(total_cost.toFixed(2));
        }
        else {
            $('input[name="contract_total_cost"]').val("");
        }
    },

    addNewContractRow: function() {
        // Calculate the key to use
        if ($('.contract-row').length > 0) {
            CustomersFormContracts.contracts_row_curr_highest_index++;
        }

        // Clone template row, change classes, and enable first inputs
        var $newRow = $('.contract-row-template').clone();
        $newRow.find('select').val('');
        $newRow.find('input:not(:checkbox):not([type=hidden])').val('');
        $newRow.removeClass('contract-row-template hidden').addClass('contract-row new-contract-row');
        $newRow.find(':input').not('.product-row-template :input').removeAttr('disabled');

        // Insert row
        if ($('.contract-row').length == 0) {
            $('.contracts .no-contract-rows-message').after($newRow);
            $('.contracts .no-contract-rows-message').remove();
        }
        else {
            $('.contracts .contract-row').last().after($newRow);
        }

        // Update the name elements e.g. from [0] to [1]
        $('.contract-row.new-contract-row :input[name]').each(function() {
            var $obj = $(this),
                newName = $obj.attr('name').replace(/^(contracts\[)\d+(\].+)$/, '$1' + CustomersFormContracts.contracts_row_curr_highest_index + '$2');

            $obj.closest('.new-contract-row').removeClass('new-contract-row');
            $obj.attr('name', newName);
            $obj.attr('id', newName);

        });

        // Setup newly added fields
        FieldTypeahead.init();
        Utils.setupPlaceholderForDisabledSelects();
    },

    removeContractsRow: function($lnk) {
        var curRow = $lnk.parents('.contract-row');
        curRow.remove();

        CustomersFormContracts.displayNoContractsRowsMessage();
        CustomersFormContracts.updateTotalContractCost();

        // nb don't reduce row_count so that array doesn't need re-indexing
    },

    displayNoContractsRowsMessage: function() {
        if ($('.contract-row').length == 0 && $('#contracts-panel-buttons a.add-contract').length > 0 && $('.contracts .no-contract-rows-message:visible').length == 0) {
            $('#contracts-panel-buttons').before(
                '<div class="no-contract-rows-message">To add a contract use the link below.</div>'
            );
        }
    },

    setupProducts: function() {
        // Add a new product
        $('.contracts, .cancelled-contracts').on('click','a.add-product', function(e) {
            var $lnk = $(this);
            e.preventDefault();
            CustomersFormContracts.addNewProductRow($lnk);
        });

        // Remove a contract
        $('.contracts, .cancelled-contracts').on('click', 'a.remove-product', function(e) {
            var $lnk = $(this);
            e.preventDefault();

            CustomersFormContracts.removeProductsRow($lnk);
        });

        CustomersFormContracts.displayNoProductsRowsMessage();
    },

    addNewProductRow: function($lnk) {
        var contractRow = $lnk.parents('.contract-row');

        // Calculate largest key value in use
        var existingProductKeysForCurrentContract = $('.product-row select[name*="product_id"]:visible', contractRow).map(function() {
                return $(this).attr('name').match(/\[products\]\[(\d)+\].+$/)[1];
            }).get(),
            largestProductKey = existingProductKeysForCurrentContract.length > 0 ? Math.max.apply(null, existingProductKeysForCurrentContract) : false,
            newProductKey = 0;

        if (largestProductKey || largestProductKey === 0) {
            newProductKey = largestProductKey + 1;
        }

        // Clone template row, change classes, and enable first two inputs
        var $newRow = $('.product-row-template', contractRow).clone();
        $newRow.find('select').val('');
        $newRow.find('input').val('');
        $newRow.removeClass('product-row-template hidden').addClass('product-row');
        $newRow.find(':input').removeAttr('disabled');
        $newRow.find('.date-duration, div.end-date input').prop('disabled', true);

        // Insert row
        if ($('.product-row', contractRow).length == 0) {
            $('.no-product-rows-message', contractRow).after($newRow);
            $('.no-product-rows-message', contractRow).remove();
        }
        else {
            $('.product-row', contractRow).last().after($newRow);
        }

        // Update the name columns to have the new product key
        $('.product-row:last :input[name]', contractRow).each(function() {
            var $obj = $(this),
                newName = $obj.attr('name').replace(/(\[products\]\[)\d+(\].+)$/, '$1' + newProductKey + '$2');

            $obj.attr('name', newName);
            $obj.attr('id', newName);
        });

        // // // Setup the date pickers and newly added fields
        CustomersFormContracts.setupDatepicker();
        Utils.setupPlaceholderForDisabledSelects();
    },

    removeProductsRow: function($lnk) {
        var curRow = $lnk.parents('.product-row');
        curRow.remove();

        CustomersFormContracts.displayNoProductsRowsMessage();
    },

    displayNoProductsRowsMessage: function() {
        $('.contract-row').each(function() {
            var ctx = $(this);

            if ($('.product-row', ctx).length == 0 && $('a.add-product', ctx).length > 0 && $('.no-product-rows-message', ctx).length == 0) {
                $('.no-product-rows-message-wrapper', ctx).html(
                    '<div class="no-product-rows-message">To add a product or service use the link below.</div>'
                );
            }
        });


    },

    setupDatepicker: function() {
        // Add datepicker to all inputs with date class
        if ($('.date-field .input-group.date').length && $.isFunction($.fn.datepicker)) {
            $('.date-field .input-group.date').datepicker({
                format: "dd/mm/yyyy",
                todayBtn: true,
                container: $('.date-field .input-group.date').closest('.custom-form'),
                autoclose: true,
                todayHighlight: true
            });
        }

        // On change of start date...
        $('.date-field .input-group.date.start-date').datepicker().on('changeDate', function(e) {
            var $row = $(e.currentTarget).parents('.product-row'),
                relEndDatepicker = $row.find('.input-group.date.end-date'),
                durationVal = $row.find('.date-duration').val(),
                startDate = $(this).datepicker('getDate'),
                momentStartDate = moment($(this).datepicker('getDate')),
                startDatePlusOne = new Date();

            // ... enable the duration and end date fields
            $row.find('.date-duration, .end-date input').removeAttr('disabled');

            // ... disable 'earlier' dates
            startDatePlusOne.setDate(startDate.getDate() + 1);
            relEndDatepicker.datepicker('setStartDate', startDatePlusOne);

            // ... if duration alread set, then update end date
            if (durationVal != '' && durationVal != 'custom') {
                duration = moment.duration(durationVal);
                relEndDatepicker.datepicker('update', momentStartDate.add(duration).toDate());
            }

            // ... clear 'invalid' end dates previously set
            if (relEndDatepicker.datepicker('getDate') && relEndDatepicker.datepicker('getDate') <= startDate) {
                relEndDatepicker.datepicker('clearDates');
            }

            Utils.setupPlaceholderForDisabledSelects();
        });

        // On change of the duration...
        $('.date-duration').change(function() {
            var $obj = $(this),
                val = $obj.val(),
                relStartDatepicker = $obj.parents('.product-row').find('.input-group.date.start-date'),
                relEndDatepicker = $obj.parents('.product-row').find('.input-group.date.end-date');

            // ... update the end date (based on the value of the select)
            if (relStartDatepicker.datepicker('getDate') && val != '' && val != 'custom') {
                var momentStartDate = moment(relStartDatepicker.datepicker('getDate')),
                    duration = moment.duration(val);

                relEndDatepicker.datepicker('update', momentStartDate.add(duration).toDate());
            }

        });

        // On change of end date...
        $('.date-field .input-group.date.end-date').datepicker().on('changeDate', function(e) {
            var $row = $(e.currentTarget).parents('.product-row');

            // ... ensure that duration field is set to 'custom'
            $row.find('.date-duration').val('custom');
        });
    },
};

$(function() {
    CustomersFormContracts.init();
});
