var CaseActivities = {
    ajaxUrl: '/admin/case_activities/ajaxActions',
    customer_id: null,
    contact_id: null,
    case_id: null,
    activity_owner: 'me',
    assigned_contact: 'anyone',
    show_important: false,
    show_closed_tasks: false,
    display_with_case: false,
    only_tasks: false,
    activity_type_id: 0,
    new_activity_type_id: null,
    $new_activity_due_by_field: null,
    $new_activity_advice_given_field: null,
    open_activity_modal: null,
    placeholder_activity_modal_content: null,
    activities_list_needs_refresh: false,
    action_from_modal: false,
    act_pg: 1,
    content_loading: false,

    init: function() {
        var uri = new URI();
        CaseActivities.customer_id = (uri.segment(1) == 'customers' && uri.segment(2) == 'show') ? uri.segment(3) : null;
        CaseActivities.contact_id = (uri.segment(1) == 'contacts' && uri.segment(2) == 'show') ? uri.segment(3) : null;
        CaseActivities.case_id = (uri.segment(1) == 'case_instances' && uri.segment(2) == 'show') ? uri.segment(3) : null;
        CaseActivities.show_important = uri.hasQuery('act_only_important') ? (uri.query(true).act_only_important == 'true' ? true : false) : false;
        CaseActivities.show_closed_tasks = uri.hasQuery('act_show_closed_tasks') ? (uri.query(true).show_closed_tasks == 'true' ? true : false) : false;
        CaseActivities.open_activity_modal = uri.hasQuery('act_open_modal') ? uri.query(true).act_open_modal : null;
        CaseActivities.display_with_case = $('#activity-list-container').data('display-with-case') == 1 ? true : false;
        CaseActivities.only_tasks = $('#activity-list-container').data('only-tasks') == 1 ? true : false;
        CaseActivities.activity_owner = $('#activity-list-container').data('activity-owner') ? $('#activity-list-container').data('activity-owner') : 'everyone';
        CaseActivities.assigned_contact = $('#activity-list-container').data('task-assigned-contact') ? $('#activity-list-container').data('task-assigned-contact') : 'anyone';

        CaseActivities.openDefaultActivityModal();

        CaseActivities.setupDatepicker();
        CaseActivities.$new_activity_due_by_field = $('#new_activity_due_by_field');
        CaseActivities.$new_activity_advice_given_field = $('#new_activity_advice_given_field');
        CaseActivities.new_activity_type_id = $('#case_activity_type_id').val();
        CaseActivities.showHideNewActivityDueByField();

        CaseActivities.scrollSpy();
		$(window).scroll(CaseActivities.scrollSpy);

		$('#activity-list-container').on('click', '.load-more', CaseActivities.loadOlderActivities);

        $('#activity-type-filter-label > span').text(
            $('#activity-type-filter-options li.active a').text().toLowerCase()
        );

        $('#activity-type-filter-options').on('click','li:not(.active) a', function(e) {
            CaseActivities.activityTypeFilter(e.target);
        })

        if($('.activities-panel').length > 0) {
            $('.activities-panel #important_activities_filter').click(CaseActivities.importantActivitiesFilter);
            $('.activities-panel #closed_tasks_filter').click(CaseActivities.closedTasksFilter);
        }

        $('#activity-list-container, #cases-list-container, #activity-modal').on('click','.activity-importance-toggle', function(e){
            CaseActivities.activityImportanceToggle.bind(this)(e.target);
        })

        $('#activity-list-container, #activity-modal').on('click','.toggle-task-status', function(e){
            CaseActivities.activityStatusToggle(e.target);
        })

        CasesList.setupShowMoreLinksForActivityContent();
        // $('#activity-list-container').on('click', '.activity-item-note-more a', function(e) {
        $('.activities-panel').on('click', '.activity-item-note-more a', function(e) {
            e.preventDefault();
            CasesList.swapHiddenVisibleActivityContent(e.currentTarget);
            $(this).blur();
        }).on('change', '.activity-sub-type-field', CaseActivities.lookupAdviceTypes);

        $('#activity-list-container').on('shown.bs.collapse', '.activity-list', function(e) {
            CasesList.setupShowMoreLinksForActivityContent();
        });

        $('#case_activity_type_id').change(CaseActivities.setNewActivityTypeId)
                                    .change(CaseActivities.lookupSubTypes);

        CaseActivities.lookupSubTypes.bind($('#case_activity_type_id'))();

        $('#activity-owner-filter-label > span').text(
            $('#activity-owner-filter-options li.active a').text().toLowerCase()
        );

        $('#activity-owner-filter-options').on('click','li:not(.active) a', function(e) {
            CaseActivities.activitiesOwnerFilter(e.target);
        });

        $('#activity-assigned-contact-filter-label > span').text(
            $('#activity-assigned-contact-filter-options li.active a').text()
        );

        $('#activity-assigned-contact-filter-options').on('click','li:not(.active) a', function(e) {
            CaseActivities.activitiesAssignedContactFilter(e.target);
        });

        $('.modal.activity').on('show.bs.modal', function(e) {
            CaseActivities.updateActivityModalContents(e);
        });

        $('.modal.activity').on('shown.bs.modal', function(e) {
            CaseActivities.activityModalShown(e);
        });

        $('.modal.activity').on('hidden.bs.modal', function(e) {
            CaseActivities.activityModalHidden(e);
        });

        $('.toggleCasesActivitiesPanels').on('click', function(e) {
            $('.cases-panel').toggleClass('hidden');
            $('.activities-panel').toggleClass('hidden');
            CasesList.setupShowMoreLinksForActivityContent();
        })

        CaseActivities.toggleAssignedToFilter();

        CaseActivities.setPageQueryString();

        $('#case-add-activity-form .ajaxloader').fadeOut().html('');
    },

    lookupSubTypes: function() {
        var select = this,
            activity_type = $(select).val(),
            spinner = Utils.spinner;
            // ajaxloader = $('<div class="ajaxloader"></div>').html(spinner.el);

        // console.log('lookup sub types');

        if (!$('#case-add-activity-form .ajaxloader').length) {
            $('#case-add-activity-form').append('<div class="ajaxloader"></div>');
        }

        // $('.activity-sub-type-wrapper').fadeIn().html(ajaxloader);
        $('#case-add-activity-form .ajaxloader').fadeIn().html(spinner.el);

        $.ajax({
            url: '/admin/dashboard/lookup-activity-sub-types',
            type: 'post',
            data: {
                activity_type: activity_type
            }
        }).done(function(r) {
            $('#case-add-activity-form .ajaxloader').fadeOut().html('');

            $('.advice-type-wrapper').html(r.advice_type_html);

            if (r.success && r.html) {
                $('.activity-sub-type-wrapper').html(r.html);


                // if ($('.activity-sub-type-wrapper').find('.activity-sub-type-field[type=hidden]').length)
                // {
                //     // CaseActivities.lookupAdviceTypes.bind($('.activity-sub-type-wrapper').find('.activity-sub-type-field'))();
                // }
            } else {
                $('.activity-sub-type-wrapper').html('');
            }
        });
    },

    lookupAdviceTypes: function() {
        var select = this,
            activity_sub_type = $(select).val(),
            spinner = Utils.spinner;

        // console.log('lookup advice types');

        if (!$('#case-add-activity-form .ajaxloader').length) {
            $('#case-add-activity-form').append('<div class="ajaxloader"></div>');
        }

        $('#case-add-activity-form .ajaxloader').fadeIn().html(spinner.el);

        $.ajax({
            url: '/admin/dashboard/lookup-advice-types',
            type: 'post',
            data: {
                activity_sub_type: activity_sub_type
            }
        }).done(function(r) {
            $('#case-add-activity-form .ajaxloader').fadeOut().html('');

            if (r.success && r.html) {
                $('.advice-type-wrapper').html(r.html);
            } else {
                $('.advice-type-wrapper').html('');
            }
        });
    },

    setPageQueryString: function() {
        var uri = new URI();

        uri.removeSearch('act_pg');
        uri.removeSearch('act_type_id');
        uri.removeSearch('act_open_modal');
        uri.removeSearch('act_owner');
        uri.removeSearch('act_assigned_contact');
        qs = uri.removeSearch('act_only_important');

        if (CaseActivities.activity_type_id != 0) {
            uri.setSearch('act_type_id', CaseActivities.activity_type_id);
        }
        if (CaseActivities.show_important) {
            qs = uri.addSearch('act_only_important', 'true');
        }
        if (CaseActivities.open_activity_modal)
        {
            qs = uri.setSearch('act_open_modal', CaseActivities.open_activity_modal);
        }
        if (CaseActivities.activity_owner != 'everyone')
        {
            qs = uri.setSearch('act_owner', CaseActivities.activity_owner);
        }
        if (CaseActivities.assigned_contact != 'anyone')
        {
            qs = uri.setSearch('act_assigned_contact', CaseActivities.assigned_contact);
        }

        window.history.replaceState({}, null, qs);
    },

    reloadActivities: function(wndw) {
        if (typeof wndw === 'undefined') {
            ctx = window.document;
        }
        else {
            ctx = wndw.opener.document;
        }

        var spinner = Utils.spinner;

        $('#activity-list-container .ajaxloader', ctx).fadeIn().append(spinner.el);

        // console.log('reloadActivities', CaseActivities.act_show_closed_tasks);


        $.ajax({
            url: CaseActivities.ajaxUrl,
            type: 'POST',
            data: {
                'action'                            : 'reloadActivities',
                'act_only_important'                : CaseActivities.show_important,
                'act_type_id'                       : CaseActivities.activity_type_id,
                'act_customer_id'                   : CaseActivities.customer_id,
                'act_contact_id'                    : CaseActivities.contact_id,
                'act_case_id'                       : CaseActivities.case_id,
                'act_owner'                         : CaseActivities.activity_owner,
                'act_assigned_contact'              : CaseActivities.assigned_contact,
                'display_with_case'                 : CaseActivities.display_with_case,
                'act_only_tasks'                    : CaseActivities.only_tasks,
                'act_show_closed_tasks'             : CaseActivities.show_closed_tasks
            },
            success: function(data) {
                CaseActivities.setPageQueryString();
                $('#activity-list-container', ctx).html(data);
                CasesList.setupShowMoreLinksForActivityContent();

                if($('#cases-list-container', ctx).length > 0) {
                    CasesList.reloadCases();
                }
            },
            error: function() {
            }
        });
    },

    activityTypeFilter: function(lnk) {
        var $link = $(lnk),
            pluralLabel = 'all activity';

        CaseActivities.activity_type_id = $link.data('case-activity-type-id');
        CaseActivities.reloadActivities();

        if ($link.text() != 'All Activity') {
            pluralLabel = ($.trim($link.text())+'s').toLowerCase();
        }

        $('#activity-type-filter-label > span').text(pluralLabel);
        $link.parent('li').addClass('active').siblings().removeClass('active');
    },

    importantActivitiesFilter: function() {
        var $btn = $(this);

        CaseActivities.show_important = !CaseActivities.show_important;
        CaseActivities.reloadActivities();

        if (!CaseActivities.show_important)
        {
            $btn.find('i.fa').removeClass('fa-star').addClass('fa-star-o');
        }
        else
        {
            $btn.find('i.fa').removeClass('fa-star-o').addClass('fa-star');
        }

        CaseActivities.passVariablesToAttachmentsWindow();
    },

    closedTasksFilter: function() {
        var $btn = $(this);


        CaseActivities.show_closed_tasks = !CaseActivities.show_closed_tasks;
        CaseActivities.reloadActivities();

        if (!CaseActivities.show_important)
        {
            $btn.find('i.fa').removeClass('fa-square-o').addClass('fa-check-square-o');
        }
        else
        {
            $btn.find('i.fa').removeClass('fa-check-square-o').addClass('fa-square-o');
        }

        CaseActivities.passVariablesToAttachmentsWindow();
    },

    activityImportanceToggle: function(btn) {
        var $btn = $(this) || $(btn),
            spinner = Utils.spinner;

        if ($btn.hasClass('in-modal')) {
            CaseActivities.action_from_modal = true;
            $('#activity-modal .ajaxloader').fadeIn().append(spinner.el);
        }
        else {
            CaseActivities.action_from_modal = false;
            $('#activity-list-container .ajaxloader').fadeIn().append(spinner.el);
        }

        if($('#cases-list-container').length > 0) {
            $('#cases-list-container .ajaxloader').fadeIn().append(spinner.el);
        }

        var post_data = {
            'action'                            : 'toggle_importance',
            'importance_toggle_actvity_id'      : $btn.data('case-activity-id')
        };
        // console.log(post_data);
        // console.log($btn);
        $.ajax({
            url: CaseActivities.ajaxUrl,
            type: 'POST',
            data: post_data,
            success: function(data) {
                if (CaseActivities.action_from_modal) {
                    CaseActivities.updateActivityModalContentsForId($btn.data('case-activity-id'));
                    CaseActivities.activities_list_needs_refresh = true;
                }
                else {
                    if (data.success) {
                        $btn.find('.icon').toggleClass('fa-star').toggleClass('fa-star-o');
                    }

                    // Removing unnecessary reload..
                    // CaseActivities.reloadActivities();

                    CaseActivities.action_from_modal = false;
                    $('#activity-list-container .ajaxloader').fadeOut().html('');

                    if($('#cases-list-container').length > 0) {
                        $('#cases-list-container .ajaxloader').fadeOut().html('');
                    }
                }
            },
            error: function() {
                CaseActivities.action_from_modal = false;
                $('#activity-list-container .ajaxloader').fadeOut().html('');

                if($('#cases-list-container').length > 0) {
                    $('#cases-list-container .ajaxloader').fadeOut().html('');
                }
            }
        });
    },

    activityStatusToggle: function(btn) {
        var $btn = $(btn),
            spinner = Utils.spinner;

        if ($btn.hasClass('in-modal')) {
            CaseActivities.action_from_modal = true;
            $('#activity-modal .ajaxloader').fadeIn().append(spinner.el);
        }
        else {
            CaseActivities.action_from_modal = false;
            $('#activity-list-container .ajaxloader').fadeIn().append(spinner.el);
        }

        $.ajax({
            url: CaseActivities.ajaxUrl,
            type: 'POST',
            data: {
                'action'                        : 'toggle_status',
                'status_toggle_actvity_id'      : $btn.data('case-activity-id')
            },
            success: function(data) {
                if (CaseActivities.action_from_modal) {
                    CaseActivities.updateActivityModalContentsForId($btn.data('case-activity-id'));
                    CaseActivities.activities_list_needs_refresh = true;
                }
                else {
                    CaseActivities.reloadActivities();
                }
            },
            error: function() {
            }
        });
    },

    setNewActivityTypeId: function() {
        var $obj = $(this);

        if ($obj) {
            var upload_types = $('#case_activity_type_id').data('upload-types');

            CaseActivities.new_activity_type_id = $obj.val() == '' ? null : $obj.val();

            if (upload_types) {
                    var matching = $.grep(upload_types, function(e) { return e.id == CaseActivities.new_activity_type_id; })

                    if (matching.length > 0) {
                        $('.upload-button-container').show().siblings(':not(.activity-type-selector)').hide();

                    } else {
                        $('.upload-button-container').hide().siblings(':not(.activity-type-selector)').show();
                        CaseActivities.showHideNewActivityDueByField();
                        CaseActivities.showHideNewActivityAdviceGivenField();
                    }
            }

            CaseActivities.passVariablesToAttachmentsWindow();
        }
    },

    // Also shows the assigned to field
    showHideNewActivityDueByField: function() {
        var ids_to_visible_for = CaseActivities.$new_activity_due_by_field.data('visible-for-activity-types');

        if (ids_to_visible_for) {
            var matching = $.grep(ids_to_visible_for, function(e) { return e.id == CaseActivities.new_activity_type_id; })

            if (matching.length > 0) {
                CaseActivities.$new_activity_due_by_field.show()
                    .find(':input').removeAttr('disabled');
                $('#new_activity_assigned_to_field').show().find(':input').removeAttr('disabled');
            }
            else {
                CaseActivities.$new_activity_due_by_field.hide()
                    .find(':input').attr('disabled', 'disabled');
                $('#new_activity_assigned_to_field').hide().find(':input').attr('disabled', 'disabled');
            }
        }
    },

    showHideNewActivityAdviceGivenField: function() {
        var ids_to_visible_for = CaseActivities.$new_activity_advice_given_field.data('visible-for-activity-types');

        if (ids_to_visible_for) {
            var matching = $.grep(ids_to_visible_for, function(e) { return e.id == CaseActivities.new_activity_type_id; })

            if (matching.length > 0) {
                CaseActivities.$new_activity_advice_given_field.show()
                    .find(':input').removeAttr('disabled');
                // $('#new_activity_assigned_to_field').show().find(':input').removeAttr('disabled');
            }
            else {
                CaseActivities.$new_activity_advice_given_field.hide()
                    .find(':input').attr('disabled', 'disabled');
                // $('#new_activity_assigned_to_field').hide().find(':input').attr('disabled', 'disabled');
            }
        }
    },

    activitiesOwnerFilter: function(lnk) {
        var $link = $(lnk),
            spinner = Utils.spinner;

        $('#activity-list-container .ajaxloader').fadeIn().append(spinner.el);

        CaseActivities.activity_owner = $link.data('activityOwner');
        CaseActivities.toggleAssignedToFilter();
        CaseActivities.reloadActivities();


        $('#activity-owner-filter-label > span').text($link.text().toLowerCase());
        $link.parent('li').addClass('active').siblings().removeClass('active');

        CaseActivities.passVariablesToAttachmentsWindow();
    },

    activitiesAssignedContactFilter: function(lnk) {
        var $link = $(lnk),
            spinner = Utils.spinner;

        $('#activity-list-container .ajaxloader').fadeIn().append(spinner.el);

        CaseActivities.assigned_contact = $link.data('activityAssignedContact');
        CaseActivities.reloadActivities();

        $('#activity-assigned-contact-filter-label > span').text($link.text());
        $link.parent('li').addClass('active').siblings().removeClass('active');

        CaseActivities.passVariablesToAttachmentsWindow();
    },

    toggleAssignedToFilter: function() {
        if (CaseActivities.activity_owner == 'me')
        {
            $('.activity-assigned-contact-filter-wrapper').addClass('hidden');
            CaseActivities.assigned_contact = 'anyone';
            CaseActivities.setPageQueryString();

            $('#activity-assigned-contact-filter-label > span').text('anyone');
            $('#activity-assigned-contact-filter-options [data-activity-assigned-contact=anyone]').parent('li').addClass('active').siblings().removeClass('active');
        }
        else $('.activity-assigned-contact-filter-wrapper').removeClass('hidden');
    },

    passVariablesToAttachmentsWindow: function() {
        // Set various properties on the new window link for the add attachment window
        // so that when that window forces a refresh of the activities list then the filters aren't lost

        $('.upload-button-container .upload-window').attr({
            "data-show-important": CaseActivities.show_important,
            "data-activity-owner": CaseActivities.activity_owner,
            "data-activity-type-id": CaseActivities.activity_type_id
        });

    },

    setupDatepicker: function() {
        if ($('#new_activity_due_by_field .input-group.date').length && $.isFunction($.fn.datepicker)) {
            $('#new_activity_due_by_field .datepicker').datepicker('destroy');
            $('#new_activity_due_by_field .input-group.date').datepicker({
                format: "dd/mm/yyyy",
                todayBtn: "linked",
                container: $('.custom-form'),
                autoclose: true
            });
        }
    },

    activityModalHidden: function(e) {
        CaseActivities.open_activity_modal = null;

        if (CaseActivities.activities_list_needs_refresh) {
            var spinner = Utils.spinner;
            $('#activity-list-container .ajaxloader').fadeIn().append(spinner.el);
            CaseActivities.reloadActivities();
            CaseActivities.activities_list_needs_refresh = false;
        }

        if (CaseActivities.placeholder_activity_modal_content) {
            $('#activity-modal .modal-dialog.task').replaceWith(CaseActivities.placeholder_activity_modal_content);
        }

        CaseActivities.setPageQueryString();
    },

    activityModalShown: function(e) {
        var $lnk = $(e.relatedTarget);

        if ($lnk.length > 0) {
            CaseActivities.open_activity_modal = $lnk.data('case-activity-id');
            CaseActivities.setPageQueryString();
        }
    },

    openDefaultActivityModal: function() {
        if (CaseActivities.open_activity_modal) {
            $('#activity-modal').modal('show');
            CaseActivities.updateActivityModalContentsForId(CaseActivities.open_activity_modal);
        }
    },

    updateActivityModalContents: function(e) {
        var $lnk = $(e.relatedTarget)

        CaseActivities.placeholder_activity_modal_content = $('#activity-modal .modal-dialog.task');

        if ($lnk.length > 0) {
            CaseActivities.updateActivityModalContentsForId($lnk.data('case-activity-id'));
        }
    },

    updateActivityModalContentsForId: function(id) {
        var spinner = Utils.spinner;

        $('#activity-modal .ajaxloader').fadeIn().append(spinner.el);

        $.ajax({
            url: CaseActivities.ajaxUrl,
            type: 'POST',
            data: {
                'action'            : 'update_activity_modal_contents',
                'only_tasks'        : CaseActivities.only_tasks,
                'activity_id'       : id
            },
            success: function(data) {
                $('#activity-modal .modal-dialog.task').replaceWith(data);
            },
            error: function() {
            }
        });
    },

    scrollSpy: function() {
        if (CaseActivities.content_loading) {
            return;
        }
        var scrollPos = $(document).scrollTop();
    	CaseActivities.trackPaginatorPosition(scrollPos);
    },

    trackPaginatorPosition: function(scrollPos) {
    	var paginator = $('#activity-list-container .load-more');

    	if ($(paginator).length) {
	    	var paginatorBottomPos = $(paginator).offset().top - $(window).height() + 50;
            if (scrollPos  >= paginatorBottomPos) {
                $(paginator).trigger('click');
            }
	    }
    },

	loadOlderActivities: function(e) {
        e.preventDefault();

        var btn = $(this),
            spinner = Utils.spinner;

        CaseActivities.content_loading = true;
        CaseActivities.act_pg++;

        var l = $(btn).ladda();
        l.ladda('start');

        $.ajax({
            url: CaseActivities.ajaxUrl,
            type: 'POST',
            data: {
                'action'                            : 'reloadActivities',
                'act_only_important'                : CaseActivities.show_important,
                'act_type_id'                       : CaseActivities.activity_type_id,
                'act_customer_id'                   : CaseActivities.customer_id,
                'act_contact_id'                    : CaseActivities.contact_id,
                'act_case_id'                       : CaseActivities.case_id,
                'act_owner'                         : CaseActivities.activity_owner,
                'act_assigned_contact'              : CaseActivities.assigned_contact,
                'display_with_case'                 : CaseActivities.display_with_case,
                'act_only_tasks'                    : CaseActivities.only_tasks,
                'act_show_closed_tasks'             : CaseActivities.show_closed_tasks,
                'act_pg'                            : CaseActivities.act_pg
            },
            success: function(data) {
                $(btn).remove();
                $('#activity-list-container').find('.activity-list').append($(data).filter('.activity-list').html());
                CasesList.setupShowMoreLinksForActivityContent();
                CaseActivities.content_loading = false;
            },
            error: function() {
            }
        });
	},

    setupActivityEditForm: function() {
        var form = '.edit-case-activity-form';
        Utils.setupDatepicker(form, "dd/mm/yyyy");
        Utils.setupTimepicker(form);

        if($(form + ' .numeric').length && $.isFunction($.fn.numeric)) {
            $(form + ' .numeric').numeric();
        }

        $(form + ' .typeahead-lookup').each(function() {
            FieldTypeahead.initIndividualTypeahead($(this));
        });
    },

    setupActivityReallocateForm: function() {
        var form = '.reallocate-case-activity-form';

        $(form + ' .typeahead-lookup').each(function() {
            FieldTypeahead.initIndividualTypeahead($(this));
        });

        Utils.setupMultiselect();

        $(form + ' [name=customer_id]').change(function() {

            var customer_id = $(this).val(),
                activity_id = $(form).find('[name=activity_id]').val(),
                spinner = Utils.spinner;

            $(form + ' .content-container-wrapper .ajaxloader').fadeIn().append(spinner.el);

            $.ajax({
                url: '/admin/case_activities/reallocate/'+activity_id,
                type: 'post',
                data: {
                    action: 'reload-cases',
                    customer_id: customer_id
                }
            }).done(function(r) {
                if (r.success && r.html) {

                    $(form + ' .content-container-wrapper').html($(r.html).find('.content-container'));

                    CaseActivities.setupActivityReallocateForm();
                }
            });

        });
    },

    setupTaskAddForm: function() {
        var form = '.add-case-activity-form';
        Utils.setupDatepicker(form, "dd/mm/yyyy");
        Utils.setupTimepicker(form);

        $(form + ' .typeahead-lookup').each(function() {
            FieldTypeahead.initIndividualTypeahead($(this));
        });
    },

    initBundles: function() {
        $('.activities-bundle-panel').find('.bundle-option').each(function() {
            CaseActivities.toggleBundleSelect.bind(this)();
        });

        $('.activities-bundle-panel').on('change','.bundle-option:checkbox', CaseActivities.toggleBundleSelect);

        $('.activities-bundle-panel').on('click', '.bundle-select-all', CaseActivities.bundleSelectAll)
                                     .on('click', '.bundle-deselect-all', CaseActivities.bundleDeselectAll)
                                     .on('click', '.bundle-select-important', CaseActivities.bundleSelectImportant)
                                     .on('click', '.bundle-toggle-visibility', CaseActivities.bundleToggleVisibility)
                                     .on('click', '.bundle-create', CaseActivities.createBundle);
    },

    toggleBundleSelect: function() {
        if ($(this).is(':checked')) $(this).closest('.activity-item').addClass('selected');
        else $(this).closest('.activity-item').removeClass('selected');
    },

    bundleSelectAll: function(e) {
        e.preventDefault();
        $('.activities-bundle-panel').find('.bundle-option').prop('checked',true);
        $('.activities-bundle-panel').find('.bundle-option').each(function() {
            CaseActivities.toggleBundleSelect.bind(this)();
        });
    },

    bundleDeselectAll: function(e) {
        e.preventDefault();
        $('.activities-bundle-panel').find('.bundle-option').prop('checked',false);
        $('.activities-bundle-panel').find('.bundle-option').each(function() {
            CaseActivities.toggleBundleSelect.bind(this)();
        });
    },

    bundleSelectImportant: function(e) {
        e.preventDefault();
        $('.activities-bundle-panel').find('.bundle-option').prop('checked',false);
        $('.activities-bundle-panel').find('.important-activity .bundle-option').prop('checked',true);
        $('.activities-bundle-panel').find('.bundle-option').each(function() {
            CaseActivities.toggleBundleSelect.bind(this)();
        });
    },

    bundleToggleVisibility: function(e) {
        e.preventDefault();

        if ($('.activities-bundle-panel').hasClass('show-selected')) {

            $('.activities-bundle-panel').removeClass('show-selected').addClass('hide-selected');
            $('.bundle-visibility-description').html('not selected');
            $(this).attr('title','Show all');
            $(this).tooltip('fixTitle').tooltip('show');
            $(this).find('i').removeClass('fa-eye-slash').addClass('fa-eye').addClass('text-success').removeClass('text-muted');

        } else if ($('.activities-bundle-panel').hasClass('hide-selected')) {

            $('.activities-bundle-panel').removeClass('hide-selected');
            $('.bundle-visibility-description').html('all');
            $(this).attr('title','Show only selected');
            $(this).tooltip('fixTitle').tooltip('show');
            $(this).find('i').removeClass('text-success').addClass('text-muted');

        } else {

            $('.activities-bundle-panel').addClass('show-selected');
            $('.bundle-visibility-description').html('only selected');
            $(this).attr('title','Hide selected');
            $(this).tooltip('fixTitle').tooltip('show');
            $(this).find('i').removeClass('fa-eye').addClass('fa-eye-slash');

        }
    },

    createBundle: function(e) {
        e.preventDefault();

        $('#create-bundle-modal').find('.modal-content').html('<div class="ajaxloader"></div><div class="modal-body"><p>Loading...</p></div>');
        $('#create-bundle-modal').modal({
            backdrop: true,
            keyboard: false
        }).show();

        var data = $('.activities-bundle-panel .bundle-option').serialize();

        $.ajax({
            url: window.location.href,
            type: 'get',
            data: data
        }).done(function(html) {

            $('#create-bundle-modal').find('.modal-content').html(html);

            // var modal = Utils.renderModal('#create-bundle-modal', null, null, null, null, null, html, 'modal-lg');
            // modal.modal({
            //     backdrop: true,
            //     keyboard: false
            // });

            Utils.setupTinymce('#create-bundle-form');

            $('.save-bundle').on('click', CaseActivities.saveBundle);
        });
    },

    saveBundle: function(e) {
        e.preventDefault();

        var btn = this,
            form = $(this).closest('form'),
            data = $(form).serialize(), 
            spinner = Utils.spinner;

        $(form).find('.modal-footer').find('.download-zip-btn').remove();
        $(form).find('.modal-body').find('.alert').remove();

        $(form).find('.ajaxloader').fadeIn().append(spinner.el);

        $.ajax({
            url: window.location.href,
            type: 'post',
            data: data
        }).done(function(r) {
            if (r.success && r.zipfile && r.html) {
                
                $(form).find('.modal-footer').append(r.html);

                $(form).find('.modal-body').append('<div class="alert alert-success">Bundle is ready to download.</div>');

                $(form).find('.ajaxloader').fadeOut().html('');

            }
        });
    },

};


$(function() {
    CaseActivities.init();

    if ($('.activities-bundle-panel').length) {
        CaseActivities.initBundles();
    }
});
