var App = {
	keyboardInput: [],
	keyboardInputMap: {
		// 'call': 'redirectToCallPage',
		// 'doabarrelroll': 'doABarrelRoll',
		// 'find': 'openGlobalSearch',
	},
	isTyping: null,
	feelingLucky: false,
	init: function() {
		$('.login-widget-wrapper').on('click','.flip-side', function(e) {
			e.preventDefault();
			$('.login-widget-wrapper').toggleClass('flipped');
		});

		$('[data-toggle=tooltip]').tooltip();

		$('.ladda-button').ladda('bind', {
			callback: function( instance ) {
				if ($(this).hasClass('btn')) {
					var progress = 0;
					var interval = setInterval( function() {
						progress = Math.min( progress + Math.random() * 0.4, 1 );
						instance.setProgress( progress );

						if( progress === 1 ) {
							instance.stop();
							clearInterval( interval );
						}
					}, 200 );
				}
			}
		});

		$('.task-item :checked').parent().addClass('task-completed');

		$('.tasks-list').on('change', '.task-item :checkbox', function(e) {
			if ($(this).is(':checked'))	$(this).parent().addClass('task-completed');
			else $(this).parent().removeClass('task-completed');
		});

		$('.show-add-note').on('click', function(e) {
			e.preventDefault();

			var container = $('.add-note-form'),
				activity_type = $(this).data('activityType') || null,
				activity_type_select = $(container).find('[name=case_activity_type_id]'),
				activity_type_option = $(activity_type_select).find('option').filter(function () { return $(this).html() == activity_type; }).val();

			if (activity_type && activity_type_option) $(activity_type_select).val(activity_type_option);
			else $(activity_type_select).val('');

			//TODO: trigger change on select box
			CaseActivities.setNewActivityTypeId.bind(activity_type_select)();

			$(container).removeClass('hidden');
			$('[name=note]').focus();
		});

		$('.add-note-form').on('click','.close-add-note', function(e) {
			e.preventDefault();

			$('.add-note-form').addClass('hidden');
			$('[name=note]').val('');
		});

		$('body').on('keyup', App.logKeyboardInput);

		$('#search-icon-container').on('click', function(e) {
			e.preventDefault();
			App.openGlobalSearch();
		});

		$('.close-global-search').on('click', App.closeGlobalSearch);

		$('#incoming-call').on('hidden.bs.modal', App.endCall);

		$('body').on('click','.global-search', function(e) {
			if ($('body').hasClass('search-open') && $(e.target).hasClass('global-search')) {
				App.closeGlobalSearch();
				// e.preventDefault();
				// $('[name=global_search]').focus();
				e.stopPropagation();
				console.log(e);
			}
		});

		$('body').on('click','.search-results ul li[data-link]', function() {
			var link = $(this).data('link') || '/';

			var spinner = new Spinner({
				lines: 13,
				length: 6,
				width: 3,
				radius: 7
			}).spin();

			$('.global-search .form-group').append($('<span class="preloader">').html(spinner.el));
			return window.location.href = link;
		});

		$('body').on('mouseenter','.search-results ul li[data-link]', function() {
			var wrapper = $('.global-search'),
				current = $(wrapper).find('.search-results ul li.selected');

			$(wrapper).find('input').focus();

			$(current).removeClass('selected');
			$(this).addClass('selected');
		});
	},

	logKeyboardInput: function(e) {
		var code = e.keyCode || e.which;

		if (code == 13 && $(e.target).hasClass('enter-no-trigger')) {
			e.preventDefault();
			return false;
		}

		if ((code == 38 || code == 40 || code == 13) && $('.global-search').length && !$('.global-search').hasClass('hidden')) return App.navigateGlobalSearchResults(code);

		if ($(e.target).is('[name=global_search]')) {
			clearTimeout(App.isTyping);
			App.isTyping = setTimeout(App.globalSearch, 300);
		}

		if ($(e.target).is('[name=voip_lookup_contact]') && typeof VOIP !== 'undefined') {
			clearTimeout(App.isTyping);
			App.isTyping = setTimeout(VOIP.lookupContact, 300);
		}

		if ((code == 27) && !$('.global-search').hasClass('hidden')) App.closeGlobalSearch();

		if ($(e.target).is('input') || $(e.target).is('textarea') || $(e.target).is('[contenteditable]')) return;

		if ((code >= 65 && code <= 90) || (code >= 97 && code <= 122)) {
			if (App.clearInput) clearTimeout(App.clearInput);

			var key = String.fromCharCode(code);
			App.keyboardInput.push(key);

			var phrase = App.keyboardInput.join('').toLowerCase();

			if (App.keyboardInputMap.hasOwnProperty(phrase)) {
				clearTimeout(App.clearInput);
				App.keyboardInput = [];

				var func = App.keyboardInputMap[phrase],
					run = window['App'][func];

				if (typeof run === "function") run();
			}

			App.clearInput = setTimeout(function() {
				App.keyboardInput = [];
			}, 500);
		}
	},

	redirectToCallPage: function() {
		var phone_number = prompt('Phone Number');

		if (phone_number) window.location.href = '/admin/phone-numbers/incoming/'+phone_number;
	},

	interceptFakePhonecall: function() {

		if (!$('#incoming-call').hasClass('in'))
		{
			var spinner = new Spinner({
				lines: 13,
				length: 6,
				width: 3,
				radius: 7
			}).spin();

			$('#incoming-call').modal({backdrop:false});

			$('#incoming-call .preload-activity').prepend(spinner.el);

			setTimeout(function() {
				$('#incoming-call .activity-title').html('RECENT ACTIVITY');
				$('#incoming-call .spinner').remove();
				$('#incoming-call .activity-list').slideDown();
			}, 1000);
		}
	},

	endCall: function() {
		$('#incoming-call .activity-list').css({'display':'none'});
		$('#incoming-call .activity-title').html('LOADING RECENT ACTIVITY');
	},

	answerCall: function() {

	},

	openGlobalSearch: function() {
		$('body').addClass('search-open');
		$('.global-search').removeClass('hidden').find('.form-group').addClass('animated fadeInDown').find('input').val('').focus();
	},

	closeGlobalSearch: function() {
		$('body').removeClass('search-open');
		$('.global-search').addClass('hidden').find('.form-group').removeClass('animated fadeInDown').find('input').val('');
		$('.global-search .search-results ul li').remove();
	},

	globalSearch: function() {
		var wrapper = $('.global-search')
			search = $(wrapper).find('[name=global_search]'),
			phrase = $(search).val().toLowerCase(),
			results = $(wrapper).find('.search-results ul li'),
			spinner = Utils.spinner;


		$(results).removeClass('selected');

		if (phrase.length >= 3) {
			$(wrapper).find('.close-global-search').hide();
			$(wrapper).find('.ajax-loader').html(spinner.el);

			var global_search_url = '/admin/dashboard/global-search';

			$.ajax({
				url: global_search_url,
				type: 'post',
				data: {
					s: phrase
				}
			}).done(function(data) {
				$(wrapper).find('.close-global-search').show();
				$(wrapper).find('.ajax-loader').html('');
				if (data.length > 0) {

					if (App.feelingLucky) {
						return App.openFirstResult(data);
					}

					$('#search-results-container').html(data);

					var window_height = $(window).height(),
					scroll_position = $(window).scrollTop(),
					results = $(wrapper).find('.search-results ul li');

					// $(results).removeClass('selected');

					$(results).each(function(i, el) {
						if ($(el).offset().top + $(el).height() + 30 >= window_height + scroll_position) $(el).addClass('hidden');
					});
                }
			});

			// var global_search_url = '/admin/dashboard/global-search?s='+phrase;
			//
            // $.post(global_search_url, function(data) {
			// 	$(wrapper).find('.close-global-search').show();
			// 	$(wrapper).find('.ajax-loader').html('');
            //     if (data.length > 0) {
			// 		$('#search-results-container').html(data);
			//
			// 		var window_height = $(window).height(),
			// 		scroll_position = $(window).scrollTop(),
			// 		results = $(wrapper).find('.search-results ul li');
			//
			// 		$(results).removeClass('selected');;
			//
			// 		$(results).each(function(i, el) {
			// 			if ($(el).offset().top + $(el).height() + 30 >= window_height + scroll_position) $(el).addClass('hidden');
			// 		});
            //     }
            // });

		}
	},

	navigateGlobalSearchResults: function(code) {
		var wrapper = $('.global-search'),
			results = $(wrapper).find('.search-results ul li:not(.hidden)'),
			current = $(wrapper).find('.search-results ul li.selected'),
			current_index = $(results).index(current);

		$(wrapper).find('input').focus();

		if (!current.length && code == 13) {
			App.feelingLucky = true;
		} else {
			App.feelingLucky = false;
		}

		if (!wrapper.length || !results.length) return;
		switch (code) {
			case 13: // enter

				var link = $(current).data('link') || '/';

				var spinner = new Spinner({
					lines: 13,
					length: 6,
					width: 3,
					radius: 7
				}).spin();

				$('.global-search .form-group').append($('<span class="preloader">').html(spinner.el));
				return window.location.href = link;
			case 38: // up
				var next = current_index - 1 < 0 ? results.length-1 : current_index - 1;
				break;
			case 40: // down
				var next = current_index + 1 == results.length ? 0 : current_index + 1;
				break;
		}

		$(current).removeClass('selected');
		$(results).eq(next).addClass('selected');
	},

	openFirstResult: function(results_html) {
		var current = $(results_html).find('li.selected'),
			link = $(current).data('link') || '/';

		var spinner = new Spinner({
			lines: 13,
			length: 6,
			width: 3,
			radius: 7
		}).spin();

		$('.global-search .form-group').append($('<span class="preloader">').html(spinner.el));
		return window.location.href = link;
	},

	doABarrelRoll: function() {
		$('body').addClass('barrelroll');
		setTimeout(function() {
			$('body').removeClass('barrelroll');
		}, 4000);
	},
}

$(function() {
    App.init();
});
