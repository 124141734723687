var SystemNotifications = {
	init: function() {
		SystemNotifications.setupTinymce();

		$('.sendpreview-btn').on('click', SystemNotifications.sendPreview);

		$('.copy-text').on('click', function(e) {
			e.preventDefault();
			SystemNotifications.copyText(true);
		});

		$('.task-btn').on('click', Utils.submitTask);

        $('.shortcodes-wrapper').on('click','.insert-shortcode', SystemNotifications.insertShortcode);

        $('#preview-options-section select').on('change', function() {
            $('#preview-options-section select').not($(this)).val('');
        });

        SystemNotifications.changeNotificationType.bind($('.system-notification-type'))();
        $('.system-notification-type').on('change', SystemNotifications.changeNotificationType);

        $('.mailbodytext').on('keyup', SystemNotifications.countMessage);
	},

    setupSMSForm: function() {
        console.log('setupSMSForm');

        SystemNotifications.countMessage();
        $('.send-sms-form .mailbodytext').on('keyup', SystemNotifications.countMessage);

        $('.send-sms-form .sms-templates-select').on('change', SystemNotifications.selectSMSTemplate);
    },

    selectSMSTemplate: function() {
        var select = this,
            st = $(select).find('option:selected');

        if ($(st).data('text')) {
            $('.send-sms-form .mailbodytext').val($(st).data('text')).prop('readonly',true);
        } else {
            $('.send-sms-form .mailbodytext').prop('readonly',false);
        }
        SystemNotifications.countMessage();
    },

    changeNotificationType: function(e) {  
        var selected_type = $(this).val(),
            type_specific_elements = $('[data-notification-type]');

        if (selected_type)
        {
            $(type_specific_elements).addClass('hidden').find('input, select, textarea').prop('disabled',true);
            $(type_specific_elements).filter('[data-notification-type='+selected_type+']').removeClass('hidden').find('input, select, textarea').not('.disabled').prop('disabled',false);

            SystemNotifications.countMessage();
        }
    },

    countMessage: function() {
        
        if ($('.system-notification-type').val() == 'sms') {
            var sms_count = 160,
                hard_limit = 724,
                text = $('.mailbodytext').val(),
                text_length = text.length;

            if (text_length >= hard_limit) {
                if (text_length > hard_limit) {
                    var limited_text = text.substring(0, hard_limit);

                    text_length = limited_text.length;
                    $('.mailbodytext').val(limited_text);
                }
                $('.count-mailbodytext').html('<span class="text-danger">Text limit reached. </span> <span class="label label-danger">'+text_length+'/'+sms_count+'</span>');
            } else if (text_length > sms_count) {
                $('.count-mailbodytext').html('<span class="text-warning">Message will be broken down into multiple SMS\'.</span> <span class="label label-warning">'+text_length+'/'+sms_count+'</span>');
            } else {
                $('.count-mailbodytext').html('<span class="label label-success">'+text_length+'/'+sms_count+'</span>');
            }
        }
    },

	setupTinymce: function() {
        if (typeof tinymce === 'object') {

            tinymce.init({
                selector: ".mailbody",
                height: 400,
                menubar: false,
                relative_urls: false,
                spellchecker_rpc_url: '/js/tinymce/plugins/spellchecker/spellchecker/spellchecker.php',
                spellchecker_language: 'en_GB',
                spellchecker_languages : "US English=en_US,UK English=en_GB",
                document_base_url: location.protocol+location.hostname+'/',
                toolbar1: "styleselect removeformat pastetext | spellchecker | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table",
                toolbar2: "insertshortcode | link image | code visualblocks | fullscreen",
                plugins: [
                    "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
                    "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
                    "save table contextmenu directionality emoticons template paste textcolor moxiemanager"
                ],
                setup: function(editor) {
                    editor.addButton('insertshortcode', {
                        text: false,
                        tooltip: 'Insert shortcode' ,
                        icon: ' wud-embed2',
                        onPostRender: function(e) {
                            var btn = this.getEl();
                        },
                        onclick: function(e) {
                            var btn = e.target,
                            modal = Utils.renderModal('#shortcodes_modal');
                            modal.modal();
                        }
                    });
                },
            });
        }
    },

    insertShortcode: function(e) {
        e.preventDefault();
        var code = $(this).data('shortcode');

        if (code) {
            tinymce.activeEditor.focus();
            tinymce.activeEditor.selection.setContent('['+code+']');

            var modal = $(this).closest('.modal');
            modal.modal('hide');
        }
    },

    sendPreview: function(e) {
    	e.preventDefault();
    	var btn = this, c = 1;
    	var l = Ladda.create(document.querySelector('.sendpreview-btn'))
    	$('.send-preview .result').html('');
        l.start();
        var load = setInterval(function() {
            if (c < 10) {
	            l.setProgress(c / 10);
	            c++;
            } else {
                window.clearInterval(load);
            }
        }, 50);
		

    	$.ajax({
    		url: $(btn).data('action'),
    		data: {
                preview_email: $('#preview_email').val(),
                customer_option: $('#customer_option').val(),
                broker_option: $('#broker_option').val(),
                user_option: $('#user_option').val()
            },
    		method: 'post'
    	}).done(function(r) {
    		window.clearInterval(load);

       		if (r.success) {
       			$('.send-preview .result').html('<p class="alert alert-success">Preview email has been sent.</p>');
       		} else if (r.error) {
                $('.send-preview .result').html('<p class="alert alert-danger">'+r.error+'</p>');
            } else {
       			$('.send-preview .result').html('<p class="alert alert-danger">Preview email has not been sent.</p>');
            }

       		l.setProgress(0.99);
            l.stop();
    	});
    },

    copyText: function(force) {
    	if ($('#text').val()=='' || force===true) {

			var iframe = $('#html').parent().find('.mce-edit-area iframe').contents(),
				html = $(iframe).find('body')[0],
				text = SystemNotifications.getText(html).trim().replace(/ +(?= )/g,'').replace(/(?:(?:\r\n|\r|\n)\s*){2,}/gim, "\n\n");

    		$('#text').val(text);
    	}
    },


    getText: function(n) {
    	var rv = '';
	    
	    if (n.nodeType == 3) {
	        rv = n.nodeValue;
	    } else {
	        for (var i = 0; i < n.childNodes.length; i++) {
	            rv += SystemNotifications.getText(n.childNodes[i]);
	        }
	        var d = getComputedStyle(n).getPropertyValue('display');
	        if (d.match(/^block/) || d.match(/list/) || n.tagName == 'BR') {
	            rv += "\n\n";
	        }
	    }
	    return rv;
	}
};


$(function() {
    if ($('.system-notifications-form').length) {
        SystemNotifications.init();
    }
});

