var TabVisibility = {

    init: function() {
        // do nothing
    },

    setupTabsVisibilityForm: function() {
        Utils.setupSwitchers('.tabs-visibility');

        TabVisibility.preloadTabVisibility = [];

        $('.load-staff-group-tab-visibility').each(function() {
            var container = this,
                url = $(container).data('url');

            TabVisibility.preloadTabVisibility.push([container,url]);
        });

        TabVisibility.loadStaffGroupTabVisibility();

        $('.tab-field:checkbox').on('change', TabVisibility.setTabVisibility);
    },

    loadStaffGroupTabVisibility: function() {
        if (TabVisibility.preloadTabVisibility.length && $('#customer-tab-visibility').hasClass('in') && !$('#customer-tab-visibility').hasClass('paused')) {

            if (!$('.loading-visibility').length) {
                var progressbar_html = $('<div class="loading-visibility"><p class="text-muted"><small>Loading current tabs visibility for staff groups... <a href="#" class="pause-toggle">pause</a></small></p><div class="progress"><div class="progress-bar  progress-bar-striped active" role="progressbar" style="width: 0%;"></div></div></div>');
                $('.loading-visibility-wrapper').html(progressbar_html);

                $('.loading-visibility-wrapper').find('.pause-toggle').on('click',TabVisibility.togglePause);
            }

            var progressbar = $('.loading-visibility-wrapper').find('.progress-bar'),
                all = $('.load-staff-group-tab-visibility').length,
                current = TabVisibility.preloadTabVisibility.length,
                current_progress = (all-current)/all * 100;

            var item = TabVisibility.preloadTabVisibility[0],
                container = item[0],
                url = item[1];

            $.ajax(url).done(function(r) {
                if (r.success && r.html) {
                    $(container).html(r.html);
                    $(container).find('[data-toggle=tooltip]').tooltip();

                    $(progressbar).css('width',current_progress+'%');

                    TabVisibility.preloadTabVisibility.shift();
                    TabVisibility.loadStaffGroupTabVisibility();
                }
            });
        } else if (!$('#customer-tab-visibility').hasClass('paused')) {
            if ($('.loading-visibility').length) {
                $('.loading-visibility').slideUp('fast',function() {
                    $(this).remove();
                });
            }
        }
    },

    togglePause: function(e) {
        e.preventDefault();
        if ($('#customer-tab-visibility').hasClass('paused')) {
            $('#customer-tab-visibility').removeClass('paused');
            TabVisibility.loadStaffGroupTabVisibility();
            $('.loading-visibility-wrapper').find('.pause-toggle').html('pause');
            $('.loading-visibility-wrapper').find('.progress-bar').addClass('active');
        } else {
            $('#customer-tab-visibility').addClass('paused');
            $('.loading-visibility-wrapper').find('.pause-toggle').html('continue');
            $('.loading-visibility-wrapper').find('.progress-bar').removeClass('active');
        }
    },

    setTabVisibility: function() {
        var checkbox = this,
            wrapper = $(checkbox).closest('.tab-field-wrapper'),
            url = $(wrapper).data('url'),
            hide = $(checkbox).is(':checked');

        $.ajax({
            url: url,
            type: 'post',
            data: {
                hide: hide
            }
        });
    }
};


$(function() {
    TabVisibility.init();
});
