var Variables = {

	init: function() {
		$('body').on('click','.submit-variables-btn', Variables.save);
		$('body').on('click','.publish-change', Variables.publish);
		$('body').on('click','.comment-btn', Variables.saveComment);
	},

	save: function(e) {
		e.preventDefault();

		var btn = $(this),
			l = $(btn).ladda(),
			form = $(btn).closest('form');

		l.ladda('start');

		$.ajax({
			url: $(form).attr('action'),
			method: 'post',
			data: $(form).serialize()
		}).done(function (r) {

            $.each(r.errors, function(i) {
                var messages = $.map(this, function(value, index) {
                    return [value];
                });
                var row = $(form).find('.item-form-group:eq('+i+')');
                $(row).addClass('alert alert-danger').prepend('<p class="error-message">'+messages[0]+'</p>');
                l.ladda('stop');
            });
            $.each(r.removed, function(i) {
                var ii = $('.item-form-group').length - i,
                	row = $(form).find('.item-form-group:eq('+ii+')');
                $(row).remove();
            });
            $.each(r.created, function(i) {
                var ii = $('.item-form-group').length - i,
                	row = $(form).find('.item-form-group:eq('+ii+')');

                $(row).find('.item-id').val(this);
            });

            if (r.status == 'success') {
            	window.location.href = window.location.href;
            }



		});
	},

	publish: function(e) {
		e.preventDefault();

		var btn = $(this),
			form = $(btn).closest('form'),
			l = $(btn).ladda(),
			item = $(btn).closest('.item-form-group'),
			id = $(item).find('.item-id').val();

		l.ladda('start');

		$.ajax({
			url: '/admin/variables/publish',
			type: 'post',
			data: {
				id: id
			}
		}).done(function (r) {

			if (r.success) {
				$(btn).fadeOut('fast',function() {
					$(this).remove();
					$(item).find('.publish-status').removeClass('text-danger').addClass('text-success').html('Published!');
				});
			} else {
				if (r.error) {
					l.ladda('stop');
					$(item).find('.publish-status').addClass('text-danger').removeClass('text-success').html(r.error);
				}
			}
		});
	},

	saveComment: function(e) {
        e.preventDefault();

        var btn = this,
            l = $(btn).ladda(),
            form = $(btn).closest('form'),
            comment = $(form).find('[name=compliance_comment]');

        l.ladda('start');


        $.ajax({
            url: $(form).attr('action'),
            type: 'post',
            data: {
                task: 'save_comment',
                comment: $(comment).val()
            }
        }).done(function (r) {
            l.ladda('stop');
            if (r.success) {
                $(comment).val('');
                $('.comments-wrapper').load(window.location.href+' .comments-wrapper .panel-body');
            }
        });
    },
};

var VariablesReview = {

    init: function() {

        $('#samples-section a[data-toggle="tab"]').on('click', function (e) {
            $('#review-section .panel-collapse.in:not(#collapse_'+$(this).data('name')+')').collapse('hide');
            $('#review-section #collapse_'+$(this).data('name')).collapse('show');

            $('#review-section [data-toggle=collapse]:not(#collapse_'+$(this).data('name')+')').attr('aria-expanded',false);
            $('#review-section #collapse_heading_'+$(this).data('name')).attr('aria-expanded',true);
        });

        $('#review-section').on('show.bs.collapse', function (e) {

            $('#review-section .panel-collapse.in').not($(this)).collapse('hide');

            $('#samples-section a[data-name='+$(e.target).data('name')+']').tab('show');
        });


        $('.review-btn').on('click', function(e) {
            e.preventDefault();


            var btn = this,
                container = $(btn).closest('.panel-collapse'),
                the_other_btn = $(container).find('.review-btn').not(this),
                field_name = $(container).data('name'),
                l = $(btn).ladda(),
                form = $(btn).closest('form');

            $(the_other_btn).addClass('shrinked');
            l.ladda('start');

            $.ajax({
                url: $(form).attr('action'),
                type: 'post',
                data: {
                    task: $(btn).data('task'),
                    value_id: $(btn).data('valueId')
                }
            }).done(function (r) {
                l.ladda('stop');

                if (!r.success) {
                    $(the_other_btn).removeClass('shrinked');

                } else {

                    $('#samples-section a[data-toggle="tab"]').not('[data-name='+field_name+']').first().tab('show');

                    $('#review-section .panel-collapse').not('#collapse_'+field_name).first().collapse('show');

                    $('#collapse_heading_'+field_name).slideUp('fast');
                    $('#collapse_'+field_name).slideUp('fast', function() {
                        $('#collapse_heading_'+field_name).remove();
                        $(this).remove();
                    });

                    $('#tab_'+field_name).fadeOut('fast');
                    $('#samples-section a[data-name='+field_name+']').closest('li').fadeOut('fast', function() {
                        $('#tab_'+field_name).remove();
                        $(this).remove();
                    });

                    if (!$('#samples-section a[data-toggle="tab"]').not('[data-name='+field_name+']').length) {
                        $('#samples-section .nav-tabs').remove();
                        $('#samples-section').html('<p class="text-center text-success">No more changes to review. Feel free to leave a comment below.</p>');

                        $('#review-section').html('<a href="/admin/variables" class="btn btn-large btn-default">Back to list</a>');
                    }

                }
            });

        });
    },
};

$(function() {
	Variables.init();
	if ($('.variables-review').length) {
        VariablesReview.init();
    }
});
