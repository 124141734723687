var Preview = {
    collapsed: false,
    init: function() {
        $('#expand-all').on('click', Preview.collapseExpand);
        Preview.collapseExpand();

        $('.alert-danger.collapsed, .well-alert.collapsed').on('click', function(e) {
            if ($(this).hasClass('collapsed')) $(this).removeClass('collapsed');
            else if (Preview.collapsed == true) $(this).addClass('collapsed');
        });



        if( $('.preview-compliance-comment-wrapper').length) {
            $('.preview-compliance-comment-wrapper').draggable({
                handle: ".move",
            });

            $('.task-btn').on('click', Preview.submitTask);
            $('.preview-compliance-comment-wrapper').on('click', '.comments', Preview.toggleComments);
            $('.preview-compliance-comment-wrapper').on('keypress', 'textarea', function() {
                console.log($(this).val().trim());
                if ($(this).val().trim() == '') {
                    $('[data-task=reject]').prop('disabled',true);
                    $(this).closest('.preview-compliance-comment-wrapper').addClass('has-error');
                } else {
                    $('[data-task=reject]').prop('disabled',false);
                    $(this).closest('.preview-compliance-comment-wrapper').removeClass('has-error');
                }
            });
        } else {
            $('.task-btn').on('click', Utils.submitTask);
        }
    },

    collapseExpand: function() {
        if (Preview.collapsed) {
            $('#expand-all').html('<span class="wud-shrink7"></span> <span class="hidden-xs">Collapse content</span><span class="spacer"></span><span class="spacer"></span><span class="spacer"></span>');
            $('.alert-danger, .well-alert').removeClass('collapsed collasable');
            Preview.collapsed = false;
        } else {
            $('#expand-all').html('<span class="wud-enlarge7"></span> <span class="hidden-xs">Expand content</span><span class="spacer"></span><span class="spacer"></span><span class="spacer"></span>');
            $('.alert-danger, .well-alert').addClass('collapsed collasable');
            $('.alert-danger').each(function () {
                if (($(this).find('del').length || $(this).find('.del').length) && ($(this).find('ins').length || $(this).find('.ins').length)) {
                    $(this).removeClass('collapsed collasable');
                }
            });
            Preview.collapsed = true;
        }
    },


    submitTask: function(e) {
        e.preventDefault();

        var btn = this,
            task = $(btn).data('task'),
            form = $(btn).closest('form'),
            comment = $(form).find('[name=compliance_comment]').val();

        $(form).find('[name=task]').val(task);
        $(form).find('.has-error').removeClass('has-error');

        if (task == 'reject' && comment.trim() == '') {
            $(form).find('.preview-compliance-comment-wrapper').addClass('has-error').find('textarea').focus();
            return false;
        }

        if (!$(btn).hasClass('ladda-button')) {
            $(btn).addClass('ladda-button');
        }

        var l = $(btn).ladda(), c = 0;
        l.ladda('start');
        var load = setInterval(function() {
            l.ladda('setProgress', c / 100);
            c++;
            if (c == 95) {
                window.clearInterval(load);
            }
        }, 50);

        if (task && $(form).find('[name=task]').val() == task) {
            $(form).submit();
        }
    },

    toggleComments: function(e) {
        var btn = this,
            wrapper = $(btn).closest('.preview-compliance-comment-wrapper'),
            comments = $(wrapper).find('.comments-wrapper');

        if (!$(wrapper).hasClass('panel')) {
            $(wrapper).addClass('panel panel-default');
            $(comments).slideDown();
        } else {
            $(comments).slideUp('slow', function() {
                $(wrapper).removeClass('panel panel-default');

            });
        }
    }

};


$(function() {
    Preview.init();
});
