var MergeCasesForm = {
    init: function() {
        var wrapper = $('.merge-cases-wrapper'),
            case1 = $(wrapper).find('select[name=case1]').val(),
            case2 = $(wrapper).find('select[name=case2]').val();

        $('body').on('change','select.case-select', MergeCasesForm.reloadForm)
                 .on('click','.expand-cases-details', function(e) {
                    e.preventDefault();
                    $('.cases-details').toggleClass('hidden');
                 });

        MergeCasesForm.setPageQueryString(case1, case2);
    },

    reloadForm: function() {
        var wrapper = $('.merge-cases-wrapper'),
            form = $(wrapper).closest('form'),
            case1 = $(wrapper).find('select[name=case1]').val(),
            case2 = $(wrapper).find('select[name=case2]').val(),
            spinner = Utils.spinner;

        $(wrapper).find('.ajaxloader').fadeIn().append(spinner.el);

        MergeCasesForm.setPageQueryString(case1, case2);

        $.ajax({
            url: window.location.href,
            type: 'get',
            data: {
                case1: case1, 
                case2: case2
            }
        }).done(function(html) {
            $(wrapper).replaceWith($(html).find('.merge-cases-wrapper'));
            Utils.setupMultiselect();

            var case1 = $(wrapper).find('select[name=case1]').val(),
                case2 = $(wrapper).find('select[name=case2]').val();

            MergeCasesForm.setPageQueryString(case1, case2);
        });        

    },

    setPageQueryString: function(case1, case2) {
        var uri = new URI();

        uri.removeSearch('case1');
        uri.removeSearch('case2');

        if (case1 != '') {
            uri.setSearch('case1', case1);
        }
        if (case2 != '') {
            uri.setSearch('case2', case2);
        }

        window.history.replaceState({}, null, uri);
    },

}

$(function() {
    if ($('.merge-cases-wrapper').length) {
        MergeCasesForm.init();
    }
});
